import React from "react";
import { ApplicationContext } from "ApplicationContext"
import moment from "moment"

import Card from "Card"
import Modal from "Modal"
import Table from "Table"
import Header from "Header"
import Uploader from "Uploader"

export default class EventsTable extends React.PureComponent {
  state = {
    uploadingEvents: false,
  }

  toggleEventModal = () => this.setState({uploadingEvents: !this.state.uploadingEvents})

  render() {
    const { events, season } = this.props

    return(
      <Card
        title="Events"
        actions={
          <>
            <span onClick={this.toggleEventModal} className="button is-warning mr-2">Upload Events</span>
            <a href={`/api/internal/events/template`} className="button is-primary">Download Template</a>
          </>
        }
      >
        <Table
          showHeader
          headers={EVENT_HEADERS}
          formatters={EVENT_FORMATTERS}
          rows={events}
        />

        <Modal
          open={this.state.uploadingEvents}
          toggleModal={this.toggleEventModal}
        >
          <p>Upload Events! Reminder that events on the same day, will be replaced/overwritten</p>

          <Uploader
            maxFiles={1}
            endpoint={`/api/internal/events/upload?season_id=${season.id}`}
            onprocessfile={(e, f) => e ? "" : window.location.reload() }
          />
        </Modal>
      </Card>
    )
  }
}

const EVENT_HEADERS = {
  date: "Date",
  startTime: "Start Time",
  visitor: "Visitor",
  type: "Event Type",
  category: "Category",
}

const EVENT_FORMATTERS = {
  date: (e) => moment(e.date).format("MMM DD YYYY"),
  startTime: (e) => moment(e.startTime).format("hh:mma"),
}


