import "./CardReaderList.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { startCase } from "lodash"

import Input from "Input"
import Modal from "Modal"
import Table from "Table"
import Select from "Select"
import Button from "Button"
import Header from "Header"

// export default class CardReaderList extends React.PureComponent {
class CardReaderList extends React.PureComponent {
  state = {
    addingReader: false,
    authCode: "",
    venueId: "",
  }

  componentDidMount() {
    this.props.fetchData("/card_readers")
    this.props.fetchData("/venues")
  }

  setVenue = (venueId) => this.setState({venueId})

  submitReader = () => {
    const { venueId, authCode } = this.state
    const req = request.post("/card_readers", { venueId, authCode })
    req.then(({data}) => {
      if(data.cardReader.id) {
        this.setState({addingReader: false})
        this.props.fetchData("/card_readers")
      } else {
        console.log(data)
      }
    })
  }

  venueOptions = () => {
    if(!this.props.venues || this.props.venues.length == 0) return {}

    const options = {}
    this.props.venues.forEach((v) => { if(v.stripeLocationId) options[v.id] = v.name })
    return options
  }

  render() {
    if (!this.props.dataFetched) return false

    const { addingReader, authCode, venueId } = this.state
    return(
      <div className="CardReaderList">
        <Header
          text="Card Readers"
          subtitle="Another Work in Progress. Pump the breaks Vince."
        >
          <Button onClick={() => this.setState({addingReader: true})} text="Add New Reader" />
        </Header>

        <Table
          headers={{
            location: "Location",
            deviceType: "Type",
            serialNumber: "Serial Number",
            status: "Status",
          }}
          formatters={{
            status: (cr) => <span className={`tag ${cr.status == "online" ? "is-success" : "is-danger"}`}>{ startCase(cr.status) }</span>,
            deviceType: (cr) => startCase(cr.deviceType),
          }}
          rows={this.props.cardReaders || []}
        />

        <Modal
          open={addingReader}
          toggleModal={() => this.setState({addingReader: false})}
        >
          <Select
            label="Venue"
            prompt="Select Venue"
            options={this.venueOptions()}
            value={venueId}
            onChange={this.setVenue}
          />

          <Input
            label="Reader Code"
            value={authCode}
            onChange={(authCode) => this.setState({authCode})}
          />

          <div className="is-flex">
            <Button
              className="ml-auto"
              disabled={!(authCode && venueId)}
              onClick={this.submitReader}
              text="Register Reader"
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default usesFeatures(["data"], CardReaderList)
