import "./PayoutView.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"
import formatCurrency from "utils/formatCurrency"

import Card from "Card"
import Header from "Header"
import Button from "Button"
import Confirm from "Confirm"
import DisplayField from "DisplayField"

// export default class PayoutView extends React.PureComponent {
class PayoutView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const payoutId = this.props.match.params.id
    this.props.fetchData(`/internal/payouts/${payoutId}`)
  }

  renderBalance = (title, balance) => {
    return(
      <>
      <h4 className="subtitle">{ title }</h4>
        <div className="columns">
          { balance.map((b, i) => {
            return(
              <div className="column" key={i} >
                <Card
                  title={`${b.currency.toUpperCase()} Balance`}
                >
                  <h2 className="title">{ `$${(b.amount/100).toFixed(2)}` }</h2>
                </Card>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  sendPayout = () => {
    const { payout } = this.props

    this.setState({sending: true})
    let req = request.post(`/internal/payouts/${payout.id}/send_payout`)
    req.then(({data}) => {
      this.setState({sending: false})
      if (data.success) {
        this.props.fetchData(`/internal/payouts/${payout.id}`)
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { payout, balances } = this.props
    const { account, events } = payout

    return(
      <div className="PayoutView">
        <Header
          text={`Payout for ${account.name} - ${payout.dateRange}`}
          backLink="/payouts"
          actions={true}
        >
          <Confirm action={this.sendPayout} message="Confirm you want to send the payout">
            { !payout.sent && <Button text="Send Payout" loading={this.state.sending} /> }
          </Confirm>
        </Header>
        <div className="columns">
          <div className="column">
            <h3 className="title">Payout Details</h3>
            <DisplayField label="Payout Total" value={payout.amount} />
            <DisplayField label="Sent On" value={payout.sentOn ? formatDate(payout.sentOn) : "Not Sent"} />
            <h4 className="subtitle">Events</h4>
            { events.map((event, i) => {
              return(
                <Card
                  title={event.name}
                  key={i}
                >
                  <div className="columns">
                    <div className="column">
                      <DisplayField label="Event Date" value={event.date} />
                    </div>
                    <div className="column">
                      <DisplayField label="Payout Amount" value={formatCurrency(event.totals.payoutTotal)} />
                    </div>
                  </div>
                </Card>
              )
            })}
          </div>
          <div className="column">
            <h3 className="title">Account Balances</h3>
            <div className="columns">
              <div className="column">
                { this.renderBalance("Available for Payout", balances.available) }
              </div>
              <div className="column">
                { this.renderBalance("Pending Balance", balances.pending) }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], PayoutView)
