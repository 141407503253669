import "./PayoutForm.scss";

import React from "react";
import { Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { sumBy } from "lodash"

import Icon from "Icon"
import Card from "Card"
import Button from "Button"
import Loader from "Loader"
import EntitySelector from "EntitySelector"

// export default class PayoutForm extends React.PureComponent {
class PayoutForm extends React.Component {
  state = {
    balances: {},
    events: [],
    selectedEvents: [],
    isSubmitting: false,
  }

  accountChange = (accountId) => {
    this.props.updateField("accountId", accountId)
    this.setState({balances: {}, events: [], fetching: true})

    const req = request.get("/internal/payouts/new", { params: { accountId: accountId }})
    req.then(({data}) => {
      this.setState({balances: data.balance, events: data.events, fetching: false})
    })
  }

  payoutTotal = () => {
    let payout = 0.00
    const events = this.state.events.filter((e) => this.state.selectedEvents.includes(e.id))
    return sumBy(events, (o) => o.totals.payoutTotal)
  }

  payoutIsValid = () => {
    const selectedTotal = this.payoutTotal()
    const availableInStripe = this.state.balances.available[0].amount
    return selectedTotal > 0 && selectedTotal <= availableInStripe
  }

  submitPayout = () => {
    let payoutData = this.props.formData
    let req = request.post("/internal/payouts", payoutData)
    req.then(({data}) => {
      if (data.success) {
        this.setState({payoutId: data.payout.id})
      } else {
        this.setState({errors: data.errors})
      }
    })
  }

  renderBalance = (title, balance) => {
    return(
      <>
      <h4 className="subtitle">{ title }</h4>
        <div className="columns">
          { balance.map((b, i) => {
            return(
              <div className="column" key={i} >
                <Card
                  title={`${b.currency.toUpperCase()} Balance`}
                >
                  <h2 className="title">{ `$${(b.amount/100).toFixed(2)}` }</h2>
                </Card>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  toggleEvent = (eventId) => {
    let { selectedEvents } = this.state
    const event = this.state.events.find((e) => e.id == eventId)
    let idx = selectedEvents.indexOf(eventId)
    if (idx > -1) {
      selectedEvents.splice(idx, 1)
    } else {
      selectedEvents.push(eventId)
    }
    this.props.updateField("events", selectedEvents)
    this.setState({selectedEvents})
  }

  render() {
    const { balances, events, selectedEvents, payoutId, fetching } = this.state

    if (payoutId) return <Redirect to={`/payouts/${payoutId}`} />

    return(
      <div className="PayoutForm">
        <h1 className="title">New Payout</h1>
        <EntitySelector
          depth="account"
          onChange={this.accountChange}
          requiredFields={{
            account: [ "stripe_id" ],
          }}
        />

        { fetching && <Loader /> }

        { balances.object &&
          <>
            <h3 className="title mt-4">Account Balances</h3>
            <div className="columns">
              <div className="column">
                { this.renderBalance("Available for Payout", balances.available) }
              </div>
              <div className="column">
                { this.renderBalance("Pending Balance", balances.pending) }
              </div>
            </div>
          </>
        }
        { balances.object &&
          <div className="s-card my-2">
            <h3 className="title">
              <span className="title has-text-weight-normal mr-4">Pending Payout Amount:</span>
              <span className={this.payoutIsValid() ? "" : "has-text-danger"}>
                ${ (this.payoutTotal() / 100).toFixed(2) }
              </span>
            </h3>
          </div>
        }
        { events.length > 0 &&
          <>
            <h3 className="title mt-4">Unpaid Events</h3>
            <div className="columns is-multiline">
              { events.map((event, i) => {
                const selected = selectedEvents.includes(event.id)
                return(
                  <div className="column is-half" key={i} onClick={this.toggleEvent.bind(null, event.id)}>
                    <Card
                      title={event.name}
                      actions={selected ? <Icon icon="check" /> : <Icon />}
                      className={`Event ${selected ? "selected" : ""}`}
                    >
                      <p>{event.date}</p>
                      <p className="is-size-5">
                        <span className="has-text-weight-bold mr-2">Payout Amount:</span>
                        ${ (event.totals.payoutTotal / 100).toFixed(2) }
                      </p>
                    </Card>
                  </div>
                )
              })}
            </div>
          </>
        }
        { balances.object &&
          <Button
            text="Build Payout"
            disabled={this.payoutTotal() == 0 || !this.payoutIsValid()}
            loading={this.state.isSubmitting}
            onClick={this.submitPayout}
          />
        }
      </div>
    )
  }
}

export default usesFeatures(["form"], PayoutForm)
