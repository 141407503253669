import "./AccountList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"

import Table from "Table"
import Header from "Header"

class AccountList extends React.PureComponent {
  componentDidMount() {
    this.props.fetchData("/internal/accounts", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="AccountList">
        <Header
          text="Accounts"
        />
        <Table
          newRecordPath="/accounts/new"
          newRecordText="New Account"
          empty={<h3>No accounts for some reason...</h3>}
          showHeader={true}
          headers={ACCOUNT_HEADERS}
          formatters={ACCOUNT_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const ACCOUNT_HEADERS = {
  name: "Name",
  stripeId: "Stripe Id",
  feeStructure: "Catch Fee",
  otherFeeStructure: "Tax + Tip Fee",
  country: "Country",
  currency: "Currency",
  active: "Active",
  stripe: "Stripe Onboarding Complete?",
  contractStart: "Contract Start",
  contractEnd: "Contract End",
}

const ACCOUNT_FORMATTERS = {
  name: (a) => <Link to={`/accounts/${a.id}`}>{ a.name }</Link>,
  stripe: (a) => a.detailsSubmitted ? "Yes" : "No",
  active: (a) => a.active ? "Yes" : "No",
  country: (a) => a.country.name,
  currency: (a) => a.country.currency,
  feeStructure: (a) => `${a.feeStructure}%`,
  otherFeeStructure: (a) => `${a.otherFeeStructure}%`,
  contractStart: (a) => a.contractStart ? formatDate(a.contractStart) : "N/A",
  contractEnd: (a) => a.contractEnd ? formatDate(a.contractEnd) : "N/A",
}

const TD_CLASSES = {
  edit: "is-narrow",
  stripe: "is-narrow",
}

export default usesFeatures(["data", "pagination"], AccountList)

