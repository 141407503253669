import "./UserForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"
import Header from "Header"
import Select from "Select"

// export default class UserForm extends React.PureComponent {
class UserForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const userId = this.props.match.params.id
    this.props.fetchData(`internal/users/${userId}/edit`, this.props.seedFormData)
  }

  handleSubmit = () => {
    let url = `internal/users/${this.props.formData.id}`
    this.setState({processing: true})
    let req = request.patch(url, { user: this.props.formData })
    req.then(({data}) => {
      if (data.success) {
        this.setState({saved: true})
      } else {
        this.setState({processing: false, errors: data.errors})
      }
      console.log(data)
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.saved) return <Redirect to={`/users/${this.props.formData.id}`} />

    const { formData, venues, updateField } = this.props
    return(
      <div className="UserForm">
        <Header
          text={`Editing ${this.props.user.name}`}
          backLink={`/users/${this.props.formData.id}`}
        />
        <Form
          onSubmit={this.handleSubmit}
          title={`Edit User`}
        >
          <Input label="Name" value={formData.name} onChange={updateField.bind(null, "name")} />
          <Input
            label="Email"
            value={formData.email}
            onChange={updateField.bind(null, "email")}
            errors={this.state.errors || {}}
            errorKey="email"
          />
          <div className="message is-warning">
            <div className="message-body">
              Making changes to a users email will immedietly impact their authentication abilities
            </div>
          </div>
          <Select
            label="Role"
            value={formData.type}
            onChange={updateField.bind(null, "type")}
            options={{
              venue: "Venue User",
              admin: "Venue Admin",
              vendor: "Vendor User",
              internal: "Internal (Catch Employee)",
            }}
          />
          <Select
            label="Venue"
            value={formData.venueId}
            onChange={updateField.bind(null, "venueId")}
            prompt="No Venue"
            options={this.props.venues}
          />

          <div className="Actions">
            <button className={`button is-primary ${this.state.processing ? "is-loading" : ""}`}>Save</button>
            <Link className="button is-outlined is-link" to={`/users/${this.props.formData.id}`}>Cancel</Link>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], UserForm)
