import "./EventView.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"
import { upperFirst } from "lodash"

import Card from "Card"
import Table from "Table"
import Header from "Header"
import Button from "Button"
import DisplayField from "DisplayField"

// export default class EventView extends React.PureComponent {
class EventView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchData(`internal/events/${id}`)
  }

  eventHeader = () => {
    const { event } = this.props
    if (event.past < 0) {
      return [ event.name, "Event has not yet happened" ].join(" - ")
    } else if (event.past == 0) {
      return [ event.name, "EVENT IN PROGRESS" ].join(" - ")
    } else if (event.past < 3) {
      return [ event.name, "Event recently ended" ].join(" - ")
    } else {
      return event.name
    }
  }

  createInvoice = () => {
    this.setState({creatingInvoice: true})
    const data = { eventId: this.props.event.id }
    const req = request.post("internal/invoices", data)
    req.then(({data}) => {
      if (data.success) {
        this.setState({invoiceId: data.invoice.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.invoiceId) return <Redirect to={`/invoices/${this.state.invoiceId}`} />

    const { event } = this.props

    return(
      <div className="EventView">
        <Header
          text={this.eventHeader()}
          backLink="/events"
          linkText="Events"
        />

        <div className="columns">
          <div className="column">
            <Card title="Event Details">
              <DisplayField label="Date" value={moment(event.date).format("MMM DD YYYY")} />
              <DisplayField label="Start Time" value={moment(event.startTime).format("hh:mma")} />
              <DisplayField label="Description" value={event.description || "None"} />
              <DisplayField label="Visitor" value={event.visitor} />
              <DisplayField label="Notes" value={event.notes || "No Notes"} />
              <DisplayField label="Attendance" value={event.visitor} />
              <DisplayField label="Type" value={event.type} />
              <DisplayField label="Category" value={event.category} />
            </Card>
          </div>
          <div className="column">
            <Card title="Financials">
              <DisplayField label="Sales" value={`$${event.sales || "0.00"}`} />
              <DisplayField label="Refunds" value={`$${event.refunds || "0.00"}`} />
              <DisplayField label="All Inclusive Sales" value={`$${event.allInclusiveSales}`} />
              <DisplayField label="All Inclusive Refunds" value={`$${event.allInclusiveRefunds}`} />

              { event.hasAnyInclusive &&
                <>
                  <h4 className="subtitle">Invoices</h4>
                  { event.invoices.length == 0 &&
                    <Button
                      text="Create Event Invoice"
                      loading={this.state.creatingInvoice}
                      onClick={this.createInvoice}
                    />
                  }
                  { event.invoices.length >= 1 &&
                    <Table
                      headers={INVOICE_HEADERS}
                      formatters={INVOICE_FORMATTERS}
                      rows={event.invoices}
                    />
                  }
                </>
              }
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const INVOICE_HEADERS = {
  number: "Invoice Number",
  date: "Date",
  status: "Status",
}

const INVOICE_FORMATTERS = {
  number: (i) => <Link to={`/invoices/${i.id}`}>Invoice #{i.number}</Link>,
  date: (i) => moment(i.date).format("MMM DD YYYY"),
  status: (i) => upperFirst(i.status),
}

export default usesFeatures(["data"], EventView)
