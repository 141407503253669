import "./UserView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import { formatDateTime } from "date_helpers"
import usesFeatures from "usesFeatures"
import { startCase } from "lodash"

import Table from "Table"
import Icon from "Icon"
import Header from "Header"
import Select from "Select"
import DisplayField from "DisplayField"


// export default class UserView extends React.PureComponent {
class UserView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedPermission: null
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchData(`internal/users/${id}`)
  }

  changePermission = (selectedPermission) => this.setState({ selectedPermission })

  addPermission = (e) => {
    e.preventDefault()
    const { user } = this.props
    const { selectedPermission } = this.state

    let req = request.patch(`internal/users/${user.id}/permission`, { permission: selectedPermission })
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/users/${user.id}`)
      }
    })
  }

  dropPermission = (p) => {
    const { user } = this.props

    let req = request.delete(`internal/users/${user.id}/permission?permission=${p}`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/users/${user.id}`)
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { user } = this.props
    return(
      <div className="UserView">
        <Header
          text="Users"
          backLink="/users"
          linkText="Users"
        >
         <Link to={`/users/${user.id}/edit`} className="button is-primary">Edit User</Link>
        </Header>
        <div className="columns">
          <div className="column is-8">
            <div className="s-card">
              <h3 className="title">{user.name}</h3>
              <DisplayField label="Email" value={user.email} />
              <DisplayField label="Account" value={user.accountName} />
              <DisplayField label="Current Venue" value={user.venueName} />
              <DisplayField label="Role" value={startCase(user.type)} />
              <DisplayField label="Last Login" value={user.lasLogin ? formatDateTime(user.lastLogin) : "Never"} />
              { user.inviteUrl &&
              <DisplayField label="Invite URL" value={
                <code className="p-2 mt-2 is-block">{user.inviteUrl}</code>
              } />
              }

              <div className="block">
                <p className="is-size-6 has-text-weight-bold">Password Reset Sent?</p>
                <p className="is-bold">{ user.passwordResetExpiration ? "Yes" : "No" }</p>
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="s-card">
              <h3 className="subtitle">Permissions</h3>
              { user.permissions.length == 0 && <em>No Permissions</em> }
              { this.props.user.permissions.map((p, i) => {
                return(
                  <div className="columns" key={i}>
                    <div className="column">
                      { startCase(p) }
                    </div>
                    <div className="column is-narrow has-text-right">
                      <span
                        className="button is-danger is-small is-outlined"
                        onClick={this.dropPermission.bind(null, p)}
                      >
                        <Icon icon="times" />
                      </span>
                    </div>
                  </div>
                )
              })}

              <div className="columns">
                <div className="column">
                  <Select
                    label="Add new permission"
                    prompt="Select Permission"
                    onChange={this.changePermission}
                    options={this.props.permissions}
                    value={this.state.selectedPermission}
                  />
                </div>
                <div className="column is-narrow is-relative">
                  <label className="label">&nbsp;</label>
                  <button
                    className="button is-primary"
                    disabled={!this.state.selectedPermission}
                    onClick={this.addPermission}
                  >
                    Add Permission
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], UserView)
