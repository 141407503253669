import React from "react";

import usesFeatures from "usesFeatures"
import request from "request"

import Card from "Card"
import Input from "Input"
import Select from "Select"
import Button from "Button"
import DatePicker from "DatePicker"

class EventForm extends React.PureComponent {
  state = {
    isSubmitting: false
  }

  canSubmit = () => true

  submitEvent = () => {
    this.setState({isSubmitting: true})
    const { seasonId, formData } = this.props
    const req = request.post(`internal/seasons/${seasonId}/events`, { event: formData })
    req.then(({data}) => {
      this.setState({isSubmitting: false})
      this.props.clearForm()
      this.props.updateEvents(data.events)
    })
  }

  render() {
    const { formData, updateField } = this.props
    return(
      <Card title="Add Event" >
        <div className="flex">
          <DatePicker
            label="Date"
            value={formData.date}
            onChange={updateField.bind(null, "date")}
          />
          <Input
            label="Event Time"
            type="number"
            value={formData.start_time}
            onChange={updateField.bind(null, "start_time")}
          />
          <Input
            label="Visitor"
            value={formData.visitor}
            onChange={updateField.bind(null, "visitor")}
          />
          <Select
            label="Type"
            options={TYPES}
            prompt="Select Type"
            value={formData.type}
            onChange={updateField.bind(null, "type")}
          />
          <Select
            label="Category"
            options={CATEGORIES}
            prompt="Select Category"
            value={formData.category}
            onChange={updateField.bind(null, "category")}
          />
        </div>
        <Button
          disabled={!this.canSubmit()}
          loading={this.state.isSubmitting}
          onClick={this.submitEvent}
          text="Create Event"
          className="mt-4"
        />
      </Card>
    )
  }
}

const TYPES = {
  unknown: "Unknown",
  baseball: "Baseball",
  football: "Football",
  soccer: "Soccer",
  basketball: "Basketball",
}

const CATEGORIES = {
  unknown: "Unknown",
  game: "Game",
  concert: "Concert",
  conference: "Conference",
  adhoc: "Ad Hoc",
}

export default usesFeatures(["form"], EventForm)
