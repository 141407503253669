import "./ReportList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"

import Card from "Card"
import Header from "Header"

export default class ReportList extends React.PureComponent {
  state = {}

  componentDidMount() {
    // this.props.fetchData("/hello_world")
  }

  render() {
    // if (!this.props.dataFetched) return false

    return(
      <div className="ReportList">
        <Header
          text="Reports"
        />
        <div className="columns">
          <div className="column is-one-third">
            <Link to="/reports/accounts">
              <Card
                title="Lifetime Account Reports"
                image="/images/internal/reports/account.png"
                className="box"
              >
              </Card>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
