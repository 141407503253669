import "./VendorList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"

import Table from "Table"
import Header from "Header"

// export default class VendorList extends React.PureComponent {
class VendorList extends React.PureComponent {
  componentDidMount() {
    this.props.fetchData("/internal/vendors", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="VendorList">
        <Header
          text="Vendors"
        />
        <Table
          newRecordPath="/vendors/new"
          newRecordText="New Vendor"
          empty={<h3>No Vendors Yet</h3>}
          showHeader={true}
          headers={VENDOR_HEADERS}
          formatters={VENDOR_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const VENDOR_HEADERS = {
  name: "Name",
  stripeId: "Stripe Id",
  accountName: "Vendor For",
  venueName: "Venue(s)",
  active: "Active",
  stripe: "Stripe Onboarding Complete?",
}

const VENDOR_FORMATTERS = {
  name: (a) => <Link to={`/vendors/${a.id}`}>{ a.name }</Link>,
  stripe: (a) => a.detailsSubmitted ? "Yes" : "No",
  active: (a) => a.active ? "Yes" : "No",
  accountName: (a) => a.parent.name,
  venueName: (a) => a.venues.map((v) => v.name).join(", "),
}

const TD_CLASSES = {
  edit: "is-narrow",
  stripe: "is-narrow",
}

export default usesFeatures(["data", "pagination"], VendorList)

