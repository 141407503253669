import "./VenueView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { capitalize } from "lodash"
import { ApplicationContext } from "ApplicationContext"
import moment from "moment"

import Card from "Card"
import Table from "Table"
import Header from "Header"
import DisplayField from "DisplayField"

import SectionsTable from "./components/SectionsTable"
import Terminals from "./components/Terminals"

class VenueView extends React.PureComponent {
  static contextType = ApplicationContext

  componentDidMount() {
    const venueId = this.props.match.params.id
    this.props.fetchData(`internal/venues/${venueId}`)
  }

  handleVenueSwitch = () => {
    let req = request.post(`internal/venues/${this.props.venue.id}/switch`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/venues/${this.props.venue.id}`)
      }
    })
  }

  changeView = (subview) => this.setState({subview})

  render() {
    if (!this.props.dataFetched) return false

    const { sections, venue } = this.props
    const { user } = this.context.CurrentUserContext
    const currentVenue = this.context.CurrentUserContext.venue || {}

    return(
      <div className="VenueView">
        <Header
          text="Venues"
          backLink="/venues"
        >
          { currentVenue && currentVenue.id != venue.id &&
            <span onClick={this.handleVenueSwitch} className="button is-warning">Switch Venue</span>
          }
          <Link to={`/venues/${venue.id}/edit`} className="button is-primary">Edit Venue</Link>
        </Header>
        <div className="columns is-multiline">
          <div className="column is-6">
            <Card
              title={
                <>
                  { venue.name }
                  { venue.id == currentVenue.id &&
                    <span className="tag is-success is-align-self-flex-start ml-2">Current Venue</span>
                  }
                </>
              }
            >
              <DisplayField label="Account" value={<Link to={`/accounts/${venue.accountId}`}>{venue.accountName}</Link>} />
              <DisplayField label="Address" value={<span style={{whiteSpace: "pre-wrap"}}>{venue.formattedAddress}</span>} />
              <DisplayField label="Charges Tax" value={venue.salesTax ? "Tax added" : "No tax"} />
              { venue.salesTax &&
                <DisplayField label="Tax Rate" value={venue.taxRate} />
              }
              <DisplayField label="Time Zone" value={venue.timeZone} />
              <DisplayField label="Sales Tax" value={venue.salesTax ? venue.taxRate : "No Sales Tax"} />
              <DisplayField label="Rewards Program" value={venue.rewardsProgram ? "Yes" : "No" } />
              <DisplayField label="Demo Venue" value={venue.demo ? "Yes" : "No" } />
              <DisplayField label="Cart Disclaimer" value={venue.cartDisclaimer} />

              <div className="DisplayField block">
                <p className="is-size-6 has-text-weight-bold">QR Code</p>
                <p className="is-bold">
                  <img src={this.props.qrCodeUrl} filename={`${venue.name} QR Code.png`} width="256" />
                  <br/>
                  <a href={`/api/internal/venues/${venue.id}/qr_code`}>Download QR Code</a>
                </p>
              </div>
            </Card>
          </div>
          <div className="column is-6">
            <Card title="Users">
              <Table
                headers={USER_HEADERS}
                showHeader={true}
                formatters={{
                  name: (u) => <Link to={`/users/${u.id}`}>{u.name}</Link>,
                  type: (u) => capitalize(u.type)
                }}
                rows={this.props.users}
              />
            </Card>
            <Card title="Terminals">
              <Terminals
                venue={venue}
                terminals={venue.terminals}
              />
            </Card>
          </div>
          <Card>
            <SectionsTable
              venue={venue}
              sections={venue.sections}
            />
          </Card>
        </div>
      </div>
    )
  }
}

const USER_HEADERS = {
  name: "Name",
  email: "Email",
  type: "Role",
}

export default usesFeatures(["data"], VenueView)
