import "./EventForm.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"

// export default class EventForm extends React.PureComponent {
class EventForm extends React.PureComponent {
  state = {}

  componentDidMount() {
    // this.props.fetchData("/hello_world")
  }

  render() {
    // if (!this.props.dataFetched) return false

    return(
      <div className="EventForm">
        Hello from EventForm
      </div>
    )
  }
}

export default usesFeatures([], EventForm)
