import "./InvoiceForm.scss";

import React from "react";
import { Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import formatCurrency from "lib/utils/formatCurrency"

import Card from "Card"
import Input from "Input"
import Loader from "Loader"
import Header from "Header"
import Button from "Button"
import CurrencyInput from "CurrencyInput"
import EntitySelector from "EntitySelector"

// export default class InvoiceForm extends React.PureComponent {
class InvoiceForm extends React.PureComponent {
  state = {
    balances: {},
    invoiceId: null,
    isSubmitting: false,
  }

  accountChange = (accountId) => {
    this.props.updateField("accountId", accountId)
    this.setState({balances: {}, events: [], fetching: true})

    const req = request.get(`/internal/accounts/${accountId}/balances`)
    req.then(({data}) => {
      this.setState({balances: data.balance, events: data.events, fetching: false})
    })
  }

  renderBalance = (title, balance) => {
    return(
      <>
      <h4 className="subtitle">{ title }</h4>
        <div className="columns">
          { balance.map((b, i) => {
            return(
              <div className="column" key={i} >
                <Card
                  title={`${b.currency.toUpperCase()} Balance`}
                >
                  <h2 className="title">{ formatCurrency(b.amount) }</h2>
                </Card>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  canCreateInvoice = () => {
    const { balances } = this.state
    const { formData } = this.props

    if(!balances || !balances.available) return false
    if(parseFloat(formData.toal) == 0) return false

    return true
  }

  createInvoice = () => {
    const { formData } = this.props
    console.log(formData)
    const req = request.post("/internal/invoices", { invoice: formData })
    req.then(({data}) => {
      if(data.success && data.invoice.id) {
        this.setState({invoiceId: data.invoice.id})
      }
    })
  }

  render() {
    const { balances, fetching, invoiceId } = this.state
    const { formData, updateField } = this.props

    if (invoiceId) return <Redirect to={`/invoices/${invoiceId}`} />

    return(
      <div className="InvoiceForm">
        <Header
          text="New Invoice"
        />
        <div className="columns is-centered">
          <div className="column is-half">
            <EntitySelector
              depth="account"
              onChange={this.accountChange}
              requiredFields={{
                account: [ "stripe_id" ],
              }}
            />
            { fetching && <Loader /> }

            { balances.object &&
              <>
                <h3 className="title mt-4">Account Balances</h3>
                <div className="columns">
                  <div className="column">
                    { this.renderBalance("Current Balance", balances.available) }
                  </div>
                  <div className="column">
                    { this.renderBalance("Pending Balance", balances.pending) }
                  </div>
                </div>
                <CurrencyInput
                  label="Charge Amount"
                  value={formData.total || "0"}
                  prefix={"$"}
                  onChange={updateField.bind(null, "total")}
                  className="mt-4"
                />
              </>
            }
            <div className="mt-4">
              <Input
                label="Charge Description"
                value={formData.description}
                onChange={updateField.bind(null, "description")}
                disabled={!formData.accountId}
              />
            </div>
            <div className="is-flex mt-4">
              <Button
                text="Create Invoice"
                className="ml-auto"
                disabled={!this.canCreateInvoice()}
                onClick={this.createInvoice}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["form"], InvoiceForm)
