import "./VendorForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"

import Form from "Form"
import Input from "Input"
import Select from "Select"
import DatePicker from "DatePicker"
import CurrencyInput from "CurrencyInput"
import EntitySelector from "EntitySelector"

class VendorForm extends React.PureComponent {
  state = {}

  componentDidMount() {
    const accountId = this.props.match.params.id
    if (accountId) {
      this.props.fetchData(`internal/accounts/${accountId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("internal/accounts/new")
    }
  }

  updateEntities = (accountId) => {
    this.props.updateField("accountId", accountId)
  }

  canSubmit = () => {
    const { formData } = this.props
    return formData.accountId && formData.name
  }

  handleSubmit = () => {
    let req = request.post("internal/vendors/save", { vendor: this.props.formData })
    req.then(({data}) => {
      if (data.success) {
        this.setState({success: true, id: data.vendor.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.success) return <Redirect to={`/vendors/${this.state.id}`} />

    const { formData, updateField } = this.props

    return(
      <div className="VendorForm">
        <Header text="Vendors" />
        <Form
          title={`${formData.id ? "Update" : "New"} Vendor`}
          onSubmit={this.handleSubmit}
        >
          <Input
            label="Vendor Name"
            value={formData.name}
            onChange={updateField.bind(null, "name")}
          />
          { !formData.id &&
            <EntitySelector
              depth="account"
              onChange={this.updateEntities}
              accountLabel="Vendor for"
              venueLabel="At"
            />
          }

          <Input
            label="Statement Descriptor"
            hint="Descriptor capped at 22 characters, aim for 14 or under here"
            value={formData.statementId || ""}
            append={`${(formData.statementId || "").length}`}
            onChange={updateField.bind(null, "statementId")}
          />

          <div className="Actions">
            <Link to="/vendors" className="button is-outlined is-link">Cancel</Link>
            <button className="button is-primary" disabled={!this.canSubmit()}>{ formData.id ? "Update" : "Create" } Vendor</button>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], VendorForm)
