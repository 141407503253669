import "./AccountForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"

import Form from "Form"
import Input from "Input"
import Select from "Select"
import Switch from "Switch"
import DatePicker from "DatePicker"
import CurrencyInput from "CurrencyInput"

// export default class AccountForm extends React.PureComponent {
class AccountForm extends React.PureComponent {
  state = {}

  componentDidMount() {
    const accountId = this.props.match.params.id
    if (accountId) {
      this.props.fetchData(`internal/accounts/${accountId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("internal/accounts/new")
    }
  }

  handleSubmit = () => {
    let req = request.post("internal/accounts/save", { account: this.props.formData })
    req.then(({data}) => {
      if (data.success) {
        this.setState({success: true, id: data.account.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.success) return <Redirect to={`/accounts/${this.state.id}`} />

    const { formData, updateField } = this.props

    return(
      <div className="AccountForm">
        <Header text="Accounts" />
        <Form
          title={`${formData.id ? "Update" : "New"} Account`}
          onSubmit={this.handleSubmit}
        >
          <Input
            label="Account Name"
            value={formData.name}
            onChange={updateField.bind(null, "name")}
          />
          <Input
            label="Statement Descriptor"
            hint="Descriptor capped at 22 characters, aim for 14 or under here"
            value={formData.statementId || ""}
            append={`${(formData.statementId || "").length}`}
            onChange={updateField.bind(null, "statementId")}
          />
          <Select
            label="Account Country"
            prompt="Select Country"
            value={formData.countryCode}
            onChange={updateField.bind(null, "countryCode")}
            options={this.props.isoCodes}
          />
          <p className="mb-4 mt-0">
            Example Descriptor:
            <span className="has-text-weight-bold ml-2">
              { `CATCH* @${formData.statementId}`.substring(0, 22) }
            </span>
          </p>
          <div className="columns">
            <div className="column">
              <DatePicker
                label="Contract Start"
                value={formData.contractStart}
                onChange={updateField.bind(null, "contractStart")}
              />
            </div>
            <div className="column">
              <DatePicker
                label="Contract End"
                value={formData.contractEnd}
                onChange={updateField.bind(null, "contractEnd")}
              />
            </div>
          </div>
          <h4 className="subtitle">Fees</h4>
          <Input
            label="Fee Structure"
            type="number"
            value={formData.feeStructure || 15}
            append={"%"}
            onChange={updateField.bind(null, "feeStructure")}
          />
          <Input
            label="Tax + Tip Fee Structure"
            type="number"
            value={formData.otherFeeStructure || 15}
            append={"%"}
            onChange={updateField.bind(null, "otherFeeStructure")}
          />
          <CurrencyInput
            label="Per-transaction Fee"
            value={formData.transactionFee || "0"}
            prefix={"$"}
            onChange={updateField.bind(null, "transactionFee")}
          />
          <Input
            label="All Inclusive Fee Structure"
            type="number"
            value={formData.allInclusiveFeeStructure || 10}
            append={"%"}
            onChange={updateField.bind(null, "allInclusiveFeeStructure")}
          />
          <Input
            label="Customer Fee Structure"
            type="number"
            value={formData.customerFeeStructure || 0}
            append={"%"}
            onChange={updateField.bind(null, "customerFeeStructure")}
          />
          <Input
            label="Customer Fee Descriptor"
            hint="What do we call the customer fee on the cart"
            value={formData.customerFeeDescriptor || 0}
            onChange={updateField.bind(null, "customerFeeDescriptor")}
          />
          <h4 className="subtitle">Terminal</h4>
          <div className="even-row">
            <Input
              label="Terminal Structure"
              type="number"
              value={formData.terminalFeeStructure || 3}
              append={"%"}
              onChange={updateField.bind(null, "terminalFeeStructure")}
            />
            <CurrencyInput
              label="Terminal Transaction Fee"
              value={formData.terminalTransactionFee || "15"}
              prefix={"$"}
              onChange={updateField.bind(null, "terminalTransactionFee")}
            />
            <div className="ml-4">
              <Switch
                onChange={updateField.bind(null, "terminalCustomerFees")}
                label="Customer Pays?"
                value={formData.terminalCustomerFees}
              />
              <p>
                Fees Paid By:
                <strong>
                  { formData.terminalCustomerFees ? "Customer" : "Account" }
                </strong>
              </p>
            </div>
          </div>

          <div className="Actions">
            <Link to="/accounts" className="button is-outlined is-link">Cancel</Link>
            <button className="button is-primary">{ formData.id ? "Update" : "Create" } Account</button>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], AccountForm)
