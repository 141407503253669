import React from "react";
import { Redirect, Switch } from 'react-router-dom'

export default class ProtectedRoutes extends React.Component {

  render() {
    const Component = this.props.component;
    const isAuthenticated = this.props.auth.isAuthenticated()

    if (!isAuthenticated) return <Redirect to={{ pathname: '/login' }} />

    return this.props.children
  }
}

