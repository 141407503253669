import "./LoginView.scss";

import React from "react";
import { Link } from "react-router-dom";
import Input from "Input"

import request from "request";
import User from "User";

export default class LoginView extends React.Component {
  constructor (props) {
    super(props)

    const search = this.props.location.search;
    const reauth = !!(new URLSearchParams(search).get("reauth"))

    this.state = {
      user: {},
      reauth,
    }
  }

  updateField = (field, value) => {
    let user = this.state.user
    user[field] = value
    this.setState({user: user})
  }

  handleLogin = (e) => {
    e.preventDefault()
    const { user } = this.state
    let req = request.post("login", { email: user.email, password: user.password })
    req.then(({data}) => {
      if (data.success) {
        User.setAuthToken(data.auth_token)
        const lastPage = User.getLastPage()
        User.clearLastPage()
        this.props.history.push(lastPage || "/")
      } else {
        this.setState({errors: "Authentication Failed" })
      }
    })
  }

  render() {
    const { user } = this.state
    return(
      <div>
        { this.state.reauth && (
          <div className="notification is-warning">
            Your session has expired. Please reauthenticate
          </div>
        )}

        <h2 className="title">Internal Tools</h2>

        <form className="block" onSubmit={this.handleLogin}>
          <Input label="Email" onChange={this.updateField.bind(null, "email")} value={user.email} />
          <Input label="Password" type="password" onChange={this.updateField.bind(null, "password")} value={user.password} />

          <div className="block">
            <input type="submit" onClick={this.handleLogin} className="button is-vcentered is-outlined is-primary" value="Login" />
          </div>
        </form>

        <div className="block">
        { this.state.errors && (
          <p className="has-text-centered has-text-danger">Login Error - Please check your email, password, and try again</p>
        )}
        </div>
      </div>
    )
  }
}
