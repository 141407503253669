import "./OrderView.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDateTime, eventTimestamp } from "date_helpers"
import { startCase } from "lodash"

import Table from "Table"
import Header from "Header"
import Card from "Card"
import DisplayField from "DisplayField"

class OrderView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const orderId = this.props.match.params.id
    this.props.fetchData(`internal/orders/${orderId}`)
  }

  renderProduct = (product, i) => {
    const { order } = this.props

    return(
      <div key={product.id} className="columns is-mobile is-vcentered">
        <div className="column">
          <p className="is-size-5 has-text-weight-semibold">
            { product.name }
            { product.quantity > 1 ? ` x${product.quantity}` : "" }
          </p>
            { product.fulfilled != product.quantity && (order.status == "complete" || order.status == "cancelled") &&
              <p>
                Refunded { product.quantity - product.fulfilled }
              </p>
            }
          { product.modifiers.length > 0 && <p>
            <span className="mr-2 has-text-weight-bold">Extras:</span>
            { product.modifiers.map((m) => m.name).join(", ") }
          </p> }
          { !order.allInclusive && <p>${ product.price }</p> }
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { order, events } = this.props
    const { customer } = order

    return(
      <div className="OrderView">
        <Header
          text="Order"
          backLink="/orders"
        />
        <div className="columns">
          <div className="column is-6">
            { customer &&
              <Card title="Customer">
                <DisplayField label="Name" value={customer.name} />
                <DisplayField label="Phone" value={customer.phone} />
                <DisplayField label="First Seen" value={formatDateTime(customer.createdAt)} />
              </Card>
            }
            <Card title="Order">
              <DisplayField label="Type" value={startCase(order.type)} />
              <DisplayField label="Payment Type" value={startCase(order.paymentType)} />
              <DisplayField label="Method" value={order.hasDelviery ? "Delivery" : "Pickup"} />
              <DisplayField label="Auto Progressed?" value={order.progressed ? "Yes" : "No"} />
              <DisplayField label="All Inclusive" value={order.allInclusive ? "Yes" : "No"} />
              <DisplayField label="Placed At" value={formatDateTime(order.placedAt)} />
              <DisplayField label="Completed At" value={formatDateTime(order.completedAt)} />
            </Card>
            <Card title="Order Details">
              { order.products.map(this.renderProduct) }
              <div className="">
                <p>
                  <span className="has-text-weight-bold">Items Subtotal</span>
                  <span className="ml-2">${ order.subtotal }</span>
                </p>
                <p>
                  <span className="has-text-weight-bold">Sales Tax</span>
                  <span className="ml-2">${ order.taxes }</span>
                </p>
                { order.tip > 0 &&
                  <p>
                    <span className="has-text-weight-bold">Tip</span>
                    <span className="ml-2">${ order.tip.toFixed(2) }</span>
                  </p>
                }
                { order.customerFee != "$0.00" &&
                  <p>
                    <span className="has-text-weight-bold">Customer Fee</span>
                    <span className="ml-2">{ order.customerFee }</span>
                  </p>
                }
                <p>
                  <span className="has-text-weight-bold">Total</span>
                  <span className="ml-2">${ order.chargeTotal }</span>
                </p>
              </div>
            </Card>
          </div>
          <div className="column is-6">
            <Card title="Event Timeline">
              <Table
                rows={events}
                headers={EVENT_HEADERS}
                formatters={EVENT_FORMATTERS}
              />
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const EVENT_HEADERS = {
  prettyType: "Event Type",
  timestamp: "Time",
}

const EVENT_FORMATTERS = {
  timestamp: (e) => eventTimestamp(e.timestamp)
}

export default usesFeatures(["data"], OrderView)
