import "./EventList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Icon from "Icon"
import Table from "Table"
import Header from "Header"
import Paginate from "Paginate"

class EventList extends React.PureComponent {
  state = {
    scope: "Today"
  }

  componentDidMount() {
    this.props.fetchData("/internal/events", this.props.setPageData)
  }

  changeScope = (scope) => {
    this.setState({scope})
    this.props.fetchData(`/internal/events?scope=${scope}`, this.props.setPageData)
  }

  changePage = (page) => {
    this.props.getPage(`internal/events?scope=${this.state.scope}`, page)
  }

  renderScopeButton = (scope, label) => {
    return(
      <p className="control">
        <span
          className={`button is-primary ${ this.state.scope == scope ? "" : "is-outlined" }`}
          onClick={this.changeScope.bind(null, scope)}
        >
          { label || scope }
        </span>
      </p>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="EventList">
        <Header
          text="Events"
          actions={true}
        >
          <div className="Dates field has-addons button-group">
            { this.renderScopeButton("Past") }
            { this.renderScopeButton("Yesterday") }
            { this.renderScopeButton("Today") }
            { this.renderScopeButton("Tomorrow") }
            { this.renderScopeButton("Future") }
          </div>
        </Header>
        <Table
          empty={<h3>No events yet</h3>}
          showHeader={true}
          headers={EVENT_HEADERS}
          formatters={EVENT_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
        <Paginate
          changePage={this.changePage}
          {...this.props.pagy}
        />
      </div>
    )
  }
}

const EVENT_HEADERS = {
  name: "Name",
  visitor: "Visitor",
  hasAnyInclusive: "AI Stores?",
  hasInvoices: "Invoices?",
  date: "Date",
  startTime: "Starts (My Time)",
  type: "Type",
  category: "Category",
}

const EVENT_FORMATTERS = {
  name: (e) => <Link to={`/events/${e.id}`}>{ e.name }</Link>,
  date: (e) => moment(e.date).format("MMM DD YYYY"),
  startTime: (e) => moment(e.startTime).format("hh:mma"),
  hasAnyInclusive: (e) => <Icon icon={e.hasAnyInclusive ? "check" : "times"} />,
  hasInvoices: (e) => <Icon icon={e.hasInvoices ? "check" : "times"} />,
}

const TD_CLASSES = {
  edit: "is-narrow",
  stripe: "is-narrow",
}

export default usesFeatures(["data", "pagination"], EventList)
