import "./PermissionList.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { startCase } from "lodash"

import Table from "Table"
import Header from "Header"

import Form from "Form"
import Input from "Input"

// export default class PermissionList extends React.PureComponent {
class PermissionList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/internal/permissions")
  }

  createPermission = () => {
    let req = request.post("/internal/permissions", this.props.formData)
    req.then(({data}) => {
      if (data.success) {
        this.props.clearForm()
        this.props.fetchData("/internal/permissions")
      }
    })
  }

  formatters = () => {
    return({
      name: (p) => startCase(p.name),
      //delete: (p) => <a className="button is-danger">Delete</a>
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="PermissionList">
        <Header text="User Permissions" />
        <div className="columns">
          <div className="column is-6">
            <Table
              empty={<h3>No permissions yet</h3>}
              showHeader={true}
              headers={PERMISSION_HEADERS}
              formatters={this.formatters()}
              tdClasses={TD_CLASSES}
              rows={this.props.permissions}
            />

          </div>
          <div className="column is-3">
            <Form
              title="New Permission"
              onSubmit={this.createPermission}
            >
            <Input
              label="Permission Name"
              onChange={this.props.updateField.bind(null, "name")}
              value={this.props.formData.name}
            />

              <button
                className="button is-primary"
                disabled={!this.props.formData.name}
              >
                Create Permission
              </button>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

const PERMISSION_HEADERS = {
  name: "Permission Name",
  userCount: "Users with Permission",
  //delete: "",
}

const TD_CLASSES = {
  delete: "is-narrow"
}


export default usesFeatures(['data', 'form'], PermissionList)
