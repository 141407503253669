import "./VendorView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"
import { capitalize } from "lodash"
import { ApplicationContext } from "ApplicationContext"

import Table from "Table"
import Header from "Header"
import DisplayField from "DisplayField"

import Card from "Card"
import Form from "Form"
import Input from "Input"
import Select from "Select"

import AccountUsers from "Internal/AccountUsers"

class VendorView extends React.PureComponent {
  state = {}
  static contextType = ApplicationContext

  componentDidMount() {
    const vendorId = this.props.match.params.id
    this.props.fetchData(`internal/vendors/${vendorId}`)
  }

  createStripe = () => {
    if (this.state.stripeDisabled) return false
    this.setState({stripeDisabled: true})
    const { vendor } = this.props
    let req = request.post(`internal/accounts/${vendor.id}/stripe`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/accounts/${vendor.id}`)
      }
    })
  }

  stripeLink = () => {
    //test/connect/accounts/acct_1JmfjXPmRCpPYMLz/activity
    let urlParts = [
      "https://dashboard.stripe.com",
      "connect/accounts",
      this.props.vendor.stripeId
    ]
    if (process.env.NODE_ENV == "development") urlParts.splice(1, 0, "test")

    return urlParts.join("/")
  }

  handleAccountSwitch = () => {
    let req = request.post(`internal/accounts/${this.props.vendor.id}/switch`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/accounts/${this.props.vendor.id}`)
      }
    })
  }

  venueOptions = () => {
    const opts = {}
    this.props.venues.map((v) => opts[v.id] = v.name)
    return opts
  }

  handleAddVenue = () => {
    if (this.state.addingVenue) return false

    const { formData } = this.props

    this.setState({addingVenue: true})

    let req = request.post(`internal/vendors/${this.props.vendor.id}/add_venue`, { venueId: formData.venueId })
    req.then(({data}) => {
      if (data.success) {
        this.setState({addingVenue: false})
        this.props.clearForm()
        this.props.fetchData(`internal/vendors/${this.props.vendor.id}`)
      } else {
        this.setState({userErrors: data.errors, invitingUser: false})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { vendor, formData, users, updateField } = this.props
    const { user } = this.context.CurrentUserContext

    return(
      <div className="VendorView">
        <Header
          text="Vendors"
          backLink="/vendors"
          linkText="Back to Vendors"
          actions={true}
        >
          <Link to={`/vendors/${vendor.id}/edit`} className="button is-primary">Edit Vendor</Link>
          { vendor.stripeId && <a href={this.stripeLink()} target="_blank" className="button is-link">View in Stripe</a> }
          { vendor.id != user.accountId &&
            <span onClick={this.handleAccountSwitch} className="button is-warning">Switch to Vendor</span>
          }
          { !vendor.stripeId &&
            <span className={`button is-link ${this.state.stripeDisabled ? "is-loading" : ""}`} onClick={this.createStripe}>Create Stripe Account</span>
          }
        </Header>
        <div className="columns">
          <div className="column is-6">
            <Card
              title={
                <>
                { vendor.name }
                { vendor.id == user.accountId &&
                  <span className="tag is-success is-align-self-flex-start ml-4">Current</span>
                }
                </>
              }
            >
              <DisplayField label="Vendor at" value={<Link to={`/accounts/${vendor.parent.id}`}>{vendor.parent.name}</Link>} />
              <DisplayField label="Statement Descriptor" value={`CATCH* @${vendor.statementId}`} />
              <DisplayField
                label="Stripe Status"
                value={`
                  Details Submitted? ${vendor.detailsSubmitted ? "Yes":"No"}
                  -
                  Charges Enabled? ${vendor.chargesEnabled ? "Yes":"No"}
                `}
              />
            </Card>
          </div>
          <div className="column is-6">
            <Card title="Venues">
              <Table
                headers={VENUE_HEADERS}
                showHeader={true}
                formatters={{
                  name: (v) => <Link to={`/venues/${v.id}`}>{v.name}</Link>,
                }}
                empty="No Venues"
                rows={vendor.venues}
              />
              <Form
                wrapper={false}
                title="Add Venue to Vendor"
                onSubmit={this.handleAddVenue}
              >
                <Select
                  label="Venue"
                  value={formData.venueId}
                  onChange={updateField.bind(null, "venueId")}
                  options={this.venueOptions()}
                  prompt="Select Venue"
                />

                <button
                  className={`button is-link ${this.state.addingVenue ? "is-loading" : ""}`}
                  disabled={!formData.venueId}
                >
                  Add Venue
                </button>
              </Form>
            </Card>
            <AccountUsers
              account={vendor}
              users={this.props.users}
              venues={vendor.venues}
              role="vendor"
              fetchData={this.props.fetchData}
            />
          </div>
        </div>
      </div>
    )
  }
}

const VENUE_HEADERS = {
  name: "Name",
}



export default usesFeatures(["data", "form"], VendorView)
