import "./Uploader.scss";

import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

export default class Uploader extends React.PureComponent {
  state = {}

  render() {
    return(
      <div className="Uploader">
        <FilePond
          ref={ref => (this.pond = ref)}
          allowMultiple={this.props.maxFiles > 1}
          server={this.props.endpoint}
          name="files"
          credits=""
          { ...this.props }
        />
      </div>
    )
  }
}
