import "./SalesToolsView.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Select from "Select"
import Input from "Input"
import Card from "Card"

// export default class SalesToolsView extends React.PureComponent {
class SalesToolsView extends React.PureComponent {
  state = {
    demoVenue: null,
    areYouSure: 0,
    dataSubmitted: false,
    stagedUrl: "",
    qrUrl: "",
  }

  componentDidMount() {
    this.props.fetchData("/internal/sales")
  }

  selectVenue = (demoVenue) => this.setState({demoVenue, dataSubmitted: 0, areYouSure: 0})
  venueOptions = () => {
    const opts = {}
    this.props.venues.map((v) => opts[v.id] = v.name)
    return opts
  }

  handleCreateData = () => {
    const { demoVenue, areYouSure } = this.state

    if (areYouSure < 3) {
      this.setState({areYouSure: areYouSure + 1})
    } else {
      const req = request.post("/internal/sales/demo_data", { venue: demoVenue })
      req.then(() => this.setState({dataSubmitted: true, areYouSure: 0, demoVenue: null}))
    }
  }

  qrCodeSource = () => {
    const url = this.state.qrUrl
    return `https://chart.googleapis.com/chart?chl=${encodeURIComponent(url)}&chs=500x500&cht=qr`
  }

  render() {
    if (!this.props.dataFetched) return false

    console.log(this.props)
    const { demoVenue, areYouSure, dataSubmitted } = this.state
    return(
      <div className="SalesToolsView">
        <Header
          text="Sales Tools"
          subtitle="Hello Vince. Here are some tools for you"
        />

        <div className="columns">
          <div className="s-card column is-half">
            <h3 className="title is-4">Create Demo Data</h3>
            <p>Small description of what this does. Off the top of my head it will remove all prior events, create 5 new ones, and maybe 20-50 sales per event?</p>

            <Select
              label="Select Venue"
              options={this.venueOptions()}
              prompt="Select Demo Venue to Create Data"
              onChange={this.selectVenue}
              value={demoVenue}
            />

            { areYouSure > 0 &&
              <p className="warning">Are you sure? This will remove some orders and such</p>
            }
            { areYouSure > 1 &&
              <p className="warning">Are you really sure? You may disrupt other people's workflows</p>
            }
            { areYouSure > 2 &&
              <p className="warning">Last chance. You're about to create demo data for { this.props.venues.find((x) => x.id == demoVenue).name }</p>
            }
            { !!dataSubmitted &&
              <p className="message is-success">Demo data being generated. Good luck</p>
            }


            <span onClick={this.handleCreateData} className={`${demoVenue ? "" : "is-disabled" } button is-primary`}>Create Demo Data</span>
          </div>
        <Card title="Generate QR Code" className="column ml-2 is-half">
          <Input label="URL" value={this.state.stagedUrl} onChange={(v) => this.setState({stagedUrl: v})} />

          <span className="button is-primary" onClick={() => this.setState({qrUrl: this.state.stagedUrl})}>
            Generate QR Code
          </span>

          { this.state.qrUrl &&
            <div>
              <img width={200} src={this.qrCodeSource()} />
              <a href={this.qrCodeSource()} target="_blank">Open in new tab</a>
            </div>
          }

        </Card>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], SalesToolsView)
