import "./PrinterList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Table from "Table"
import Header from "Header"

// export default class PrinterList extends React.PureComponent {
class PrinterList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/printers", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="PrinterList">
        <Header text="Printers" />
        <Table
          empty={<h3>No printers for some reason...</h3>}
          showHeader={true}
          headers={PRINTER_HEADERS}
          formatters={PRINTER_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const PRINTER_HEADERS = {
  mac: "Priner MAC",
  model: "Model",
  accountName: "Account",
  storeName: "Store",
  lastPrint: "Last Print",
  lastPing: "Last Ping MM-DD-Time",
}

const PRINTER_FORMATTERS = {
  mac: (p) => <Link to={`/printers/${p.id}`}>{p.mac}</Link>,
  accountName: (p) => p.account ? p.account.name : "No Account",
  storeName: (p) => p.store ? p.store.name : "No Store",
  lastPrint: (p) => moment(p.lastPrint).format("MMM Do YYYY hh:mma z"),
  lastPing: (p) => p.lastPingAt ? moment(p.lastPingAt).format("MM-DD-H:mm:ss z") : "None",
}

const TD_CLASSES = {
  edit: "is-narrow"
}

export default usesFeatures(["data", "pagination"], PrinterList)
