import "./DeliverectDashboard.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Header from "Header"
import Table from "components/Table"

// export default class DeliverectDashboard extends React.PureComponent {
class DeliverectDashboard extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/external_menus")
  }

  render() {
    if (!this.props.dataFetched) return false

    const { externalMenus } = this.props
    console.log(externalMenus)
    return(
      <div className="DeliverectDashboard">
        <Header
          text="Deliverect"
        />
        <p className="mb-2 subtitle">
          This is a work in progress Vince. Calm the fuck down.
        </p>
        <Table
          title="Menus"
          rows={externalMenus}
          headers={{
            name: "Name",
            externalId: "Deliverect ID",
            channelLinkId: "Channel Link ID",
            venue: "Venue",
            storeNames: "Stores",
            pushedAt: "Last Pushed",
            actions: ""
          }}
          formatters={{
            venue: (em) => em.venueId ? <Link to={`/venues/${em.venueId}`}>{ em.venueName }</Link> : "No venue",
            storeNames: (em) => em.storeNames.length > 0 ? em.storeNames.join(", ") : "No Stores",
            pushedAt: (em) => <span title={moment(em.pushedAt).format()}>{ moment(em.pushedAt).fromNow() }</span>,
            actions: (em) => <>
              { !em.venueId && <a href="#">Assign to Venue</a> }
              { em.venueId && <a href="#">Assign to Store</a> }
            </>
          }}
        />
      </div>
    )
  }
}

export default usesFeatures(["data"], DeliverectDashboard)
