import "./UserList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDateTime } from "date_helpers"

import Table from "Table"
import Header from "Header"

// export default class UserList extends React.PureComponent {
class UserList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/internal/users", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="UserList">
        <Header text="Users" />
        <Table
          empty={<h3>No users for some reason...</h3>}
          showHeader={true}
          headers={USER_HEADERS}
          formatters={USER_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const USER_HEADERS = {
  name: "Name",
  email: "Email",
  accountName: "Account",
  venueName: "Current Venue",
  lastLogin: "Last Session",
  type: "Type",
}

const USER_FORMATTERS = {
  name: (u) => <Link to={`/users/${u.id}`}>{u.name}</Link>,
  type: (u) => u.type.replace(/^\w/, c => c.toUpperCase()),
  lastLogin: (u) => u.lastLogin ? formatDateTime(u.lastLogin) : "Never",
}

const TD_CLASSES = {
  edit: "is-narrow"
}

export default usesFeatures(["data", "pagination"], UserList)
