import "./EngineeringView.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"

import Icon from "Icon"
import Header from "Header"

// export default class EngineeringView extends React.PureComponent {
class EngineeringView extends React.PureComponent {
  state = {}

  componentDidMount() {
    // this.props.fetchData("/hello_world")
  }

  render() {
    // if (!this.props.dataFetched) return false

    return(
      <div className="EngineeringView">
        <Header
          text="Engineering Resources"
        />
        <div className="buttons">
          <a href="/sidekiq" target="_blank" className="button h-button is-primary">
            <Icon icon="cogs" className="icon" />
            <span>Sidekiq</span>
          </a>
          <a href="/flipper" target="_blank" className="button h-button is-primary">
            <Icon icon="flag" className="icon" />
            <span>Feature Flags</span>
          </a>
          <a href="/pghero" target="_blank" className="button h-button is-primary">
            <Icon icon="database" className="icon" />
            <span>PG Hero</span>
          </a>
        </div>
      </div>
    )
  }
}

export default usesFeatures([], EngineeringView)
