import "./SeasonView.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Card from "Card"
import Table from "Table"
import Header from "Header"
import DisplayField from "DisplayField"
import EventsTable from "./components/EventsTable"
import EventForm from "./components/EventForm"

// export default class SeasonView extends React.PureComponent {
class SeasonView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const seasonId = this.props.match.params.id
    this.props.fetchData(`internal/seasons/${seasonId}`)
  }

  updateEvents = (events) => {
    this.props.updateWith({events: events})
  }

  render() {
    if (!this.props.dataFetched) return false

    const { season, events } = this.props

    return(
      <div className="SeasonView">
        <Header
          text="Seasons"
          backLink="/seasons"
        >
          <Link to={`/seasons/${season.id}/edit`} className="button is-primary">Edit Season</Link>
        </Header>
        <div className="columns">
          <div className="column is-4">
            <Card
              title={season.name}
            >
              <DisplayField label="Start Date" value={season.startDate ? moment(season.startDate).format("MMM DD YYYY") : "N/A"} />
              <DisplayField label="End Date" value={season.endDate ? moment(season.endDate).format("MMM DD YYYY") : "N/A"} />
            </Card>

            <EventForm seasonId={season.id} updateEvents={this.updateEvents} />
          </div>
          <div className="column is-8">
            <EventsTable events={events} season={season}/>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], SeasonView)
