import "./InvoiceView.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { upperFirst } from "lodash"
import moment from "moment"
import formatCurrency from "lib/utils/formatCurrency"

import Card from "Card"
import Header from "Header"
import Button from "Button"
import Confirm from "Confirm"
import DisplayField from "DisplayField"

class InvoiceView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchData(`internal/invoices/${id}`)
  }

  chargeStripe = () => {
    this.setState({billing: true})
    const req = request.post(`/internal/invoices/${this.props.invoice.id}/charge`)
    req.then(({data}) => {
      this.setState({chargeSuccess: data.success, message: data.message, billing: false})
      this.props.updateWith({invoice: data.invoice})
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { invoice } = this.props

    return(
      <div className="InvoiceView">
        <Header
          text={`Invoice #${invoice.number}`}
          backLink="/invoices"
        />

        <div className="columns">
          <div className="column">
            <Card title="Details">
              <DisplayField label="Status" value={upperFirst(invoice.status)} />
              <DisplayField label="Description" value={invoice.description} />
              <DisplayField label="Amount" value={formatCurrency(invoice.total)} />
              <DisplayField label="Date" value={moment(invoice.date).format("MMM DD YYYY")} />
              <DisplayField label="Account" value={invoice.accountName} link={`/accounts/${invoice.accountId}`}/>
              { invoice.eventName &&
                <DisplayField label="Event" value={invoice.eventName} link={`/events/${invoice.eventId}`}/>
              }
              <hr/>
              <h3 className="subtitle is-bold">Line Items</h3>
              { invoice.lineItems.length == 0  &&
                <em>No Line Items for this invoice</em>
              }
              { invoice.lineItems.length > 0  &&
                <div>
                  <div className="columns">
                    <div className="column subtitle">
                      Description
                    </div>
                    <div className="column is-one-fifth subtitle">
                      Amount
                    </div>
                    <div className="column is-one-fifth subtitle">
                      For
                    </div>
                  </div>
                  { invoice.lineItems.map((li, idx) => {
                    return(
                      <div className="columns" key={idx}>
                        <div className="column">
                          { li.description }
                        </div>
                        <div className="column is-one-fifth">
                          ${ li.amount }
                        </div>
                        <div className="column is-one-fifth">
                          { li.for }
                        </div>
                      </div>
                    )
                  })}
                  <hr/>
                  <div className="columns">
                    <div className="column subtitle">
                      Total
                    </div>
                    <div className="column is-one-fifth subtitle">
                      ${invoice.total}
                    </div>
                    <div className="column is-one-fifth subtitle">
                    </div>
                  </div>
                </div>
              }
            </Card>
          </div>
          <div className="column is-one-quarter">
            <Card title="Actions">
              { invoice.status != "processed" &&
                <Confirm
                  action={this.chargeStripe}
                  message="Are you sure you want to bill this invoice?"
                >
                  <Button loading={this.state.billing} text="Charge Invoice" />
                </Confirm>
              }
              { this.state.chargeSuccess != null &&
                <div className={`message mt-2 p-2 ${this.state.chargeSuccess ? "is-success" : "is-danger"}`}>
                  { this.state.message }
                </div>
              }
              { invoice.status == "processed" &&
                <a href={invoice.receiptUrl} target="_blank" className="button is-link">
                  View Stripe Receipt
                </a>
              }
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], InvoiceView)
