import "./VenueForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"
import Header from "Header"
import Select from "Select"
import Switch from "Switch"
import Textarea from "Textarea"

// export default class VenueForm extends React.PureComponent {
class VenueForm extends React.PureComponent {
  state = {}

  componentDidMount() {
    const venueId = this.props.match.params.id
    if (venueId) {
      this.props.fetchData(`internal/venues/${venueId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("internal/venues/new")
    }
  }

  handleSubmit = () => {
    let params = this.props.formData
    let req = request.post("internal/venues/save", { venue: params })
    req.then(({data}) => {
      if (data.success) {
        this.setState({created: true, venueId: data.venueId})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.created) return <Redirect to={`/venues/${this.state.venueId}`} />

    const { formData, updateField } = this.props
    return(
      <div className="VenueForm">
        <Header text="Venues" backLink="/venues" />
        <Form
          title="New Venue"
          onSubmit={this.handleSubmit}
        >
          <Input
            label="Venue Name"
            value={formData.name}
            onChange={updateField.bind(null, "name")}
          />

          { !formData.id &&
            <Select
              label="Account"
              options={this.props.accounts}
              prompt="Select Account"
              value={formData.accountId}
              onChange={updateField.bind(null, "accountId")}
            />
          }

          <h4 className="subtitle my-2">Address Info</h4>
          <Input
            label="Address"
            value={formData.address}
            onChange={updateField.bind(null, "address")}
          />
          <Input
            label="City"
            value={formData.city}
            onChange={updateField.bind(null, "city")}
          />
          <Input
            label="State"
            value={formData.state}
            onChange={updateField.bind(null, "state")}
          />
          <Input
            label="Postal Code"
            value={formData.postalCode}
            onChange={updateField.bind(null, "postalCode")}
          />

          <div className="field block">
            <h3 className="mb-2 is-size-5 has-text-weight-bold">Sales Tax</h3>

            <div className="Taxes field is-grouped">
              <div className="control">


                <Switch
                  label="Charge Sales Tax?"
                  onChange={updateField.bind(null, "salesTax")}
                  value={formData.salesTax || false}
                />

                <Switch
                  label="Rewards Program?"
                  onChange={updateField.bind(null, "rewardsProgram")}
                  value={formData.rewardsProgram || false}
                />

                <Switch
                  label="Has Terminals?"
                  onChange={updateField.bind(null, "terminal")}
                  value={formData.terminal || false}
                />
              </div>
              { formData.salesTax && (
                <div className="TaxRate control">
                  <Input
                    label="Tax rate"
                    onChange={updateField.bind(null, "taxRate")}
                    value={formData.taxRate || 0}
                    append="%"
                    horizontal={true}
                  />
                </div>
              )}
            </div>
          </div>

          <Select
            label="Time zone"
            options={this.props.timeZones}
            prompt="Select Time Zone"
            value={formData.timeZone}
            onChange={updateField.bind(null, "timeZone")}
          />

          <Switch
            label="Used for Demos?"
            value={formData.demo || false}
            onChange={updateField.bind(null, "demo")}
          />

          <Textarea
            label="Cart Disclaimer"
            value={formData.cartDisclaimer}
            onChange={updateField.bind(null, "cartDisclaimer")}
            placeholder="Need a disclaimer on the cart? Add it here"
          />

          <div className="Actions">
            <Link to={`/venues${ formData.id ? `/${formData.id}` : "" }`} className="button is-outlined is-link">Cancel</Link>
            <button className="button is-primary">{ formData.id ? "Update" : "Create" } Venue</button>
          </div>

        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], VenueForm)
