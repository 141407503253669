import "./DateNavigator.scss";

import React from "react";
import moment from "moment"

import Icon from "Icon"
import DatePicker from "DatePicker"

export default class DateNavigator extends React.PureComponent {
  state = {}

  changeDate = (direction) => {
    const date = moment(this.props.date, "MMM D yyyy").add(direction, "days")
    this.selectDate(date.format("MMM D yyyy"))
  }

  selectDate = (date) => {
    this.props.onChange(date)
  }

  render() {
    return(
      <div className="DateNavigator">
        <div className="field has-addons button-group">
          <p className="control">
            <span
              className="button is-primary"
              onClick={this.changeDate.bind(null, -1)}
            >
              <Icon icon="chevron-left" />
            </span>
          </p>
          <div className="control">
            <DatePicker
              value={this.props.date}
              onChange={this.selectDate}
            />
          </div>
          <p className="control">
            <span
              className="button is-primary"
              onClick={this.changeDate.bind(null, 1)}
            >
              <Icon icon="chevron-right" />
            </span>
          </p>
        </div>
      </div>
    )
  }
}
