import "./InvoiceList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { upperFirst } from "lodash"
import moment from "moment"

import Table from "Table"
import Header from "Header"
import Paginate from "Paginate"

class InvoiceList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/invoices", this.props.setPageData)
  }

  changePage = (page) => {
    this.props.getPage(`internal/invoices?scope=${this.state.scope}`, page)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="InvoiceList">
        <Header
          text="Invoices"
        />
        <Table
          empty={<h3>No invoices yet</h3>}
          showHeader={true}
          headers={INVOICE_HEADERS}
          formatters={INVOICE_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
          newRecordPath="/invoices/new"
          newRecordText="New Invoice"
        />
        <Paginate
          changePage={this.changePage}
          {...this.props.pagy}
        />
      </div>
    )
  }
}

const INVOICE_HEADERS = {
  number: "Number",
  description: "Description",
  date: "Date",
  accountName: "Account",
  status: "Status",
  processedAt: "Processed",
  createdAt: "Created",
}

const INVOICE_FORMATTERS = {
  number: (i) => <Link to={`/invoices/${i.id}`}>#{ i.number }</Link>,
  status: (i) => upperFirst(i.status),
  date: (i) => moment(i.date).format("MMM DD YYYY"),
  processedAt: (i) => i.processedAt ? moment(i.processedAt).format("MMM DD YYYY hh:mma") : null,
  createdAt: (i) => moment(i.createdAt).format("MMM DD YYYY hh:mma"),
}

const TD_CLASSES = {
  edit: "is-narrow",
  stripe: "is-narrow",
}

export default usesFeatures(["data", "pagination"], InvoiceList)
