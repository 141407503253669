import "./SeasonList.scss";

import React from "react";
import { Link } from "react-router-dom";
import moment from "moment"
import usesFeatures from "usesFeatures"

import Table from "Table"
import Header from "Header"
import Paginate from "Paginate"

// export default class SeasonList extends React.PureComponent {
class SeasonList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/seasons")
  }

  changePage = (page) => this.props.getPage("internal/seasons", page)

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="SeasonList">
        <Header
          text="Seasons"
        />
        <Table
          empty={<h3>No seasons yet</h3>}
          showHeader={true}
          headers={SEASON_HEADERS}
          formatters={SEASON_FORMATTERS}
          rows={this.props.pageData}
          newRecordPath="/seasons/new"
          newRecordText="New Season"
        />
        <Paginate
          changePage={this.changePage}
          {...this.props.pagy}
        />
      </div>
    )
  }
}

const SEASON_HEADERS = {
  name: "Name",
  venue: "Venue",
  eventCount: "Events",
  startDate: "Starts",
  endDate: "Ends",
}

const SEASON_FORMATTERS = {
  name: (s) => <Link to={`/seasons/${s.id}`}>{ s.name }</Link>,
  venue: (s) => s.venue.name,
  startDate: (s) => s.startDate ? moment(s.startDate).format("MMM DD YYYY") : null,
  endDate: (s) => s.endDate ? moment(s.endDate).format("MMM DD YYYY") : null,
}

export default usesFeatures(["data", "pagination"], SeasonList)
