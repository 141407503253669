import "./SeasonForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"
import Header from "Header"
import Select from "Select"
import DatePicker from "DatePicker"

class SeasonForm extends React.PureComponent {
  state = {}

  componentDidMount() {
    const seasonId = this.props.match.params.id
    if (seasonId) {
      this.props.fetchData(`internal/seasons/${seasonId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("internal/seasons/new")
    }
  }

  canSubmit = () => {
    const { venueId, name, startDate, endDate } = this.props.formData
    return venueId && name
  }

  handleSubmit = () => {
    let req = request.post("internal/seasons/save", { season: this.props.formData })
    req.then(({data}) => {
      if (data.success) {
        this.setState({success: true, id: data.season.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.success) return <Redirect to={`/seasons/${this.state.id}`} />

    const { formData, updateField } = this.props
    return(
      <div className="SeasonForm">
        <Header text="Seasons" backLink="/seasons" />
        <Form
          title="New Season"
          onSubmit={this.handleSubmit}
        >
          <Input
            label="Season Name"
            value={formData.name}
            onChange={updateField.bind(null, "name")}
            placeholder="Ex: Timbers 2022-2023"
          />

          <Select
            label="Venue"
            options={this.props.venues}
            disabled={!!formData.id}
            prompt="Select Venue"
            value={formData.venueId}
            onChange={updateField.bind(null, "venueId")}
          />

          <DatePicker
            label="Start Date"
            onChange={updateField.bind(null, "startDate")}
            value={formData.startDate}
          />

          <DatePicker
            label="End Date"
            onChange={updateField.bind(null, "endDate")}
            value={formData.endDate}
          />

          <div className="Actions">
            <Link to="/seasons" className="button is-outlined is-link">Cancel</Link>
            <button disabled={!this.canSubmit()} className="button is-primary">{ formData.id ? "Update" : "Create" } Season</button>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], SeasonForm)
