import "./DashboardView.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Card from "Card"
import Table from "Table"
import Header from "Header"
import Button from "Button"
import SalesGraph from "SalesGraph"
import SparkLine from "./SparkLine"
import DateNavigator from "DateNavigator"
import Loader from "Loader"
import Icon from "Icon"

// export default class DashboardView extends React.PureComponent {
class DashboardView extends React.PureComponent {
  constructor (props) {
    super(props)

    const params = new URLSearchParams(window.location.search)
    const date = params.get("date") || moment().format()

    this.state = {
      reportDate: moment(date).format("MMM DD yyyy")
    }
  }

  componentDidMount() {
    this.props.fetchData(`/internal?date=${this.state.reportDate}`)
  }

  changeDate = (reportDate) => {
    const dateParam = moment(reportDate, "MMM D yyyy").format()
    this.props.history.replace({ pathname: "/", search: `?date=${dateParam}`, state: {isActive: true}})
    this.setState({reportDate, loading: true})
    this.props.fetchData(`/internal/?date=${dateParam}`, this.loaded)
  }

  loaded = () => this.setState({loading: false})

  render() {
    if (!this.props.dataFetched) return false

    const { reportDate } = this.state

    return(
      <div className="DashboardView if-fullwidth">
        <Header text="Dashboard">
          { !moment().isSame(moment(reportDate, "MMM DD yyyy"), "day") &&
          <Button onClick={this.changeDate.bind(null, moment().format("MMM DD yyyy"))} text="Back to Today" />
          }
          <DateNavigator
            onChange={this.changeDate}
            date={reportDate}
          />
        </Header>
        { this.state.loading && <Loader />}
        { !this.state.loading &&
          <div className="columns is-multiline">
            <div className="column is-8">
              <div className="columns is-multiline">
                <div className="column is-one-third">
                  <SparkLine
                    title="Sales"
                    data={Object.values(this.props.statsData).map((s) => s.sales)}
                    axis={this.props.dates}
                    color="green"
                  />
                </div>
                <div className="column is-one-third">
                  <SparkLine
                    title="All Inclusive"
                    data={Object.values(this.props.statsData).map((s) => s.allInclusive)}
                    axis={this.props.dates}
                    color="orange"
                  />
                </div>
                <div className="column is-one-third">
                  <SparkLine
                    title="Order Count"
                    data={Object.values(this.props.statsData).map((s) => s.orders)}
                    axis={this.props.dates}
                    color="info"
                  />
                </div>
              </div>
            </div>
            <div className="column is-4">
              <Card title={`Events on ${moment(reportDate, "MMM DD yyyy").add(1, "day").format("MMM DD, yyyy")}`}>
                <Table
                  headers={EVENT_HEADERS}
                  formatters={EVENT_FORMATTERS}
                  rows={this.props.tomorrow}
                />
              </Card>
            </div>
            <div className="column is-full">
              <Card title={`Sales for ${moment(reportDate, "MMM DD yyyy").format("MMM DD, yyyy")}`}>
                <Table
                  headers={SALES_HEADERS}
                  formatters={SALES_FORMATTERS}
                  rows={this.props.reportData}
                />
              </Card>
            </div>
            { false &&
              <div className="columns column is-8 is-multiline">
                <div className="column is-full">
                </div>
                <div className="column is-half">
                  <Card title="All-Inclusive Sales" className="mt-4">
                    <SalesGraph data={this.props.aiSales} />
                  </Card>
                </div>
                <div className="column is-half">
                  <Card title="Catch Fees" className>
                    <SalesGraph data={this.props.collectedFees} />
                  </Card>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

const EVENT_HEADERS = {
  name: "Name",
  date: "At",
  venue: "Venue",
}

const EVENT_FORMATTERS = {
  date: (e) => moment(e.startTime).format("hh:mma")
}

const SALES_HEADERS = {
  title: "Event",
  account: "Account",
  orderCount: "Orders",
  sales: "Sales",
  refunded: "Refunded",
  cancelled: "Cancelled",
  customerCount: "Customers",
  tips: "Tips",
  fees: "Catch Fees",
  stripeFees: "Stripe Fees",
  profit: "Profit",
  aiFees: "Fees from AI",
  report: "",
}

const SALES_FORMATTERS = {
  account: (e) => <Link to={`/accounts/${e.account.id}`}>{ e.account.name }</Link>,
  report: (e) => <a href={`/reports/${e.id}`} target="_blank">View Report</a>,
  aiFees: (e) => e.allInclusive.fees,
  fees: (e) => {
    const isPaidByCustomer = e.customerFees != "$0.00"
    return(
      <p style={{whiteSpace: "nowrap"}}>
        <span data-hint={`Fees paid by: ${isPaidByCustomer ? "Customer" : "Account"}`} className="hint--top mr-2">
          <Icon icon={isPaidByCustomer ? "user" : "building"} />
        </span>
        { e.fees == "$0.00" ? e.customerFees : e.fees }
      </p>
    )
  }
}


export default usesFeatures(["data", "history"], DashboardView)
