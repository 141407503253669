import "./PayoutList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"

import Table from "Table"
import Header from "Header"

// export default class PayoutList extends React.PureComponent {
class PayoutList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/payouts", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="PayoutList">
        <Header
          text="Payouts"
        />
        <Table
          newRecordPath="/payouts/new"
          newRecordText="New Payout"
          empty={<h3>No payouts</h3>}
          showHeader={true}
          headers={PAYOUT_HEADERS}
          formatters={PAYOUT_FORMATTERS}
          rows={this.props.pageData}
          //tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const PAYOUT_HEADERS = {
  account: "Account",
  dateRange: "Dates",
  amount: "Amount",
  eventCount: "Events",
  sentOn: "Sent",
  createdAt: "Created",
  view: "",
}

const PAYOUT_FORMATTERS = {
  account: (p) => p.account.name,
  amount: (p) => p.amount,
  view: (p) => <Link to={`/payouts/${p.id}`}>View</Link>,
  createdAt: (p) => formatDate(p.createdAt),

}

export default usesFeatures(["data", "pagination"], PayoutList)
