import "./VenueList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"

import Table from "Table"
import Header from "Header"

class VenueList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("/internal/venues", this.props.setPageData)
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="VenueList">
        <Header
          text="Venues"
        />
        <Table
          newRecordPath="/venues/new"
          newRecordText="New Venue"
          empty={<h3>No venues for some reason...</h3>}
          showHeader={true}
          headers={VENUE_HEADERS}
          formatters={VENUE_FORMATTERS}
          rows={this.props.pageData}
          tdClasses={TD_CLASSES}
        />
      </div>
    )
  }
}

const VENUE_HEADERS = {
  name: "Name",
  accountName: "Account",
}

const VENUE_FORMATTERS = {
  name: (a) => <Link to={`/venues/${a.id}`}>{ a.name }</Link>,
  accountName: (a) => <Link to={`/accounts/${a.accountId}`}>{ a.accountName }</Link>,
}

const TD_CLASSES = {
  edit: "is-narrow",
  stripe: "is-narrow",
}

export default usesFeatures(["data", "pagination"], VenueList)


