import React from "react";
import { ApplicationContext } from "ApplicationContext"
import moment from "moment"
import { capitalize } from "lodash"

import Modal from "Modal"
import Table from "Table"
import Header from "Header"
import Uploader from "Uploader"

export default class SectionsTable extends React.PureComponent {
  state = {
    uploadingSections: false,
  }

  toggleSectionModal = () => this.setState({uploadingSections: !this.state.uploadingSections})

  render() {
    const { sections, venue } = this.props

    return(
      <div>
        <Header
          text="Sections"
        >
          <span onClick={this.toggleSectionModal} className="button is-warning">Upload Sections</span>
          <a href={`/api/internal/sections/template`} className="button is-primary">Download Template</a>
        </Header>

        <Table
          showHeader
          headers={SECTION_HEADERS}
          formatters={SECTION_FORMATTERS}
          rows={sections}
        />

        <Modal
          open={this.state.uploadingSections}
          toggleModal={this.toggleSectionModal}
        >
          <p>Upload Sections! Reminder that sections in the same area, with the same number, will be replaced/overwritten</p>

          <Uploader
            maxFiles={1}
            endpoint={`/api/internal/venues/${venue.id}/sections/upload`}
            onprocessfile={(e, f) => e ? "" : window.location.reload() }
          />
        </Modal>
      </div>
    )
  }
}

const SECTION_HEADERS = {
  area: "Area",
  type: "Type",
  name: "Name",
  rows: "Rows",
  seats: "Seats",
}

const SECTION_FORMATTERS = {
  type: (s) => capitalize(s.type),
  rows: (s) => [ s.firstRow, s.lastRow ].join("-"),
  seats: (s) => [ s.firstSeat, s.lastSeat ].join("-"),
}

