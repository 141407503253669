import "./EntitySelector.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Select from "Select"

// export default class EntitySelector extends React.PureComponent {
class EntitySelector extends React.PureComponent {
  state = {
    accountId: null,
    venueId: null,
  }

  componentDidMount() {
    this.props.fetchData(`/internal/search/entities?${this.requiredFields()}`)
  }

  requiredFields = () => new URLSearchParams(this.props.requiredFields || {}).toString()

  depth = () => {
    const entities = [ "account", "venue", "store" ]

    return entities.indexOf(this.props.depth || "store")
  }

  changeAccount = (accountId) => {
    this.setState({accountId, loading: true, venueId: null, storeId: null, venues: null, stores: null})
    this.onChange(accountId)

    if (this.depth() > 0) {
      const req = request.get(`/internal/search/entities/${accountId}?${this.requiredFields()}`)
      req.then(({data}) => {
        this.setState({venues: data.venues, loading: false})
      })
    }
  }

  changeVenue = (venueId) => {
    this.setState({venueId, stores: null, loading: true, storeId: null})
    this.onChange(this.state.accountId, venueId)

    const { accountId } = this.state
    if (this.depth() > 1) {
      const req = request.get(`/internal/search/entities/${accountId}/${venueId}?${this.requiredFields()}`)
      req.then(({data}) => {
        this.setState({stores: data.stores, loading: false})
      })
    }
  }

  changeStore = (storeId) => {
    this.setState({storeId})
    this.onChange(this.state.accountId, this.state.venueId, storeId)
  }

  onChange = (accountId, venueId, storeId) => {
    if (this.props.onChange) {
      this.props.onChange(accountId, venueId, storeId)
    }
  }

  render() {
    if (!this.props.dataFetched) return false

    const { venues, stores, loading } = this.state

    return(
      <div className="EntitySelector">
        <Select
          label={this.props.accountLabel || "Accounts"}
          options={this.props.accounts}
          prompt="Select Account"
          value={this.state.accountId}
          onChange={this.changeAccount}
        />

        { venues && !loading && Object.keys(venues).length == 0 && <em>No Venues Found</em> }
        { venues && Object.keys(venues).length > 0 &&
          <Select
            label={this.props.venueLabel || "Venues"}
            options={this.state.venues}
            prompt="Select Venue"
            value={this.state.venueId}
            onChange={this.changeVenue}
          />
        }

        { stores && !loading && Object.keys(venues).length >= 1 && Object.keys(stores).length == 0 && <em>No Stores Found</em> }
        { stores && Object.keys(stores).length > 0 &&
          <Select
            label={this.props.storeLabel || "Stores"}
            options={this.state.stores}
            prompt="Select Store"
            value={this.state.storeId}
            onChange={this.changeStore}
          />
        }
      </div>
    )
  }
}

export default usesFeatures(["data"], EntitySelector)
