import "./PrinterView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Table from "Table"
import Icon from "Icon"
import Header from "Header"
import Select from "Select"
import Confirm from "Confirm"
import DisplayField from "DisplayField"
import EntitySelector from "EntitySelector"

class PrinterView extends React.PureComponent {
  state = {
    updatingAssignments: false
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchData(`internal/printers/${id}`)
  }

  identifyPrinter = () => {
    const req = request.post(`internal/printers/${this.props.printer.id}/identify`)
    req.then((data) => {
      this.setState({identificationSent: true})
    })
  }

  updateEntities = (accountId, venueId, storeId) => {
    this.setState({accountId, venueId, storeId})
  }

  updateAssignments = () => {
    const { accountId, storeId } = this.state
    const data = { accountId, storeId }
    const req = request.patch(`internal/printers/${this.props.printer.id}`, data)
    req.then(({data}) => {
      if (data.success) {
        window.location.reload()
      }
    })
  }


  render() {
    if (!this.props.dataFetched) return false

    const { printer } = this.props
    const { pageInfo, account, store } = printer

    console.log(this.state)

    return(
      <div className="PrinterView">
        <Header
          text="Printers"
          backLink="/printers"
          linkText="Printers"
        >
          { this.state.identificationSent &&
            <span className="message is-small is-success mt-5 p-2"><span className="message-body is-size-6">Identification Sent - Check Printer</span></span>
          }
          <span onClick={this.identifyPrinter} className="button is-primary">Print Identification</span>
        </Header>
        <div className="columns">
          <div className="column is-8">
            <div className="s-card">
              <h3 className="title">{printer.mac}</h3>
              <h3 className="subtitle">Last Print - { moment(printer.lastPrint).format("MMM Do YYYY hh:mma z") }</h3>
              <DisplayField label="Notes" value={printer.notes} />
              <DisplayField label="Model" value={printer.model} />
              <DisplayField label="Poll Interval" value={`${printer.pollInterval} Seconds`} />
              <DisplayField label="Available Encodings" value={printer.encodings.map((e, i) => <span className="encoding" key={i}>{ e }</span>)} />

              <div className="DisplayField block">
                <p className="is-size-5 has-text-weight-bold">Page Data</p>
                <DisplayField label="Ticket Width in Pixels" value={printer.printWidth} />
                <DisplayField label="Paper Width" value={pageInfo.paperWidth} />
                <DisplayField label="Print Width" value={pageInfo.printWidth} />
                <div className="columns">
                  <div className="column is-half">
                    <DisplayField label="Horizontal Resolution" value={pageInfo.horizontalResolution} />
                  </div>
                  <div className="column is-half">
                    <DisplayField label="Vertical Resolution" value={pageInfo.verticalResolution} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="s-card">
              { !this.state.updatingAssignments &&
                <div>
                  <DisplayField
                    label="Account"
                    value={account ? <Link to={`/accounts/${account.id}`}>{ account.name }</Link> : "No Account Assigned" }
                  />
                  <DisplayField
                   label="Store"
                    value={printer.store ? <Link to={`/stores/${printer.store.id}`}>{ printer.store.name }</Link> : "No Store Assigned" }
                  />
                  <Confirm
                    open={this.state.confirmUpdate}
                    message="Warning - updating printer assignments could disrupt operations"
                    action={()=> this.setState({updatingAssignments: true})}
                  >
                    <span className="button is-small is-primary">
                      Update Printer Assignments
                    </span>
                  </Confirm>
                </div>
              }
              { this.state.updatingAssignments &&
                <div>
                  <EntitySelector
                    onChange={this.updateEntities}
                    depth="store"
                  />
                  { this.state.storeId &&
                    <span className="button is-primary" onClick={this.updateAssignments}>
                      Update Assignments
                    </span>
                  }
                </div>
              }
            </div>
            <div className="s-card mt-5">
              <h3 className="is-size-6 has-text-weight-bold mb-2">Print Queue</h3>
              <Table
                showHeader={true}
                headers={JOB_HEADERS}
                formatters={JOB_FORMATTERS}
                rows={printer.jobs}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const JOB_HEADERS = {
  id: "ID",
  imageUrl: "Ticket",
  createdAt: "",
}

const JOB_FORMATTERS = {
  createdAt: (j) => moment(j.createdAt).format("MM-D-YY hh:mma z"),
  imageUrl: (j) => <a href={j.imageUrl} target="_blank">View Ticket</a>,
}

export default usesFeatures(['data'], PrinterView)
