import "./AccountReport.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Select from "Select"

// export default class AccountReport extends React.PureComponent {
class AccountReport extends React.PureComponent {
  state = {}

  componentDidMount() {
    const accountId = this.props.match.params.accountId
    if (accountId) {
      this.props.fetchData(`internal/reports/accounts/${accountId}`)
    } else {
      this.props.fetchData(`internal/reports/accounts/`, console.log)
    }
  }

  selectAccount = (accountId) => {
    this.props.history.replace({ pathname: `/reports/accounts/${accountId}`, state: {isActive: true}})
    this.props.fetchData(`internal/reports/accounts/${accountId}`)
  }

  renderStat = (name, children) => {
    return(
      <div className="column is-one-quarter">
        <div className="s-card center has-text-centered">
          <h3 className="subtitle">{ name }</h3>
          <div className="title mt-2">{ children }</div>
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { account, reportData } = this.props

    if(!account) return(
      <div className="AccountReport">
        <Header text="Lifetime Account Reports" backLink="/reports" />
        <div>
          <Select
            options={this.props.accounts}
            prompt="Select Account"
            onChange={this.selectAccount}
          />
        </div>
      </div>
    )

    console.log(reportData)
    return(
      <div className="AccountReport">
        <Header
          text={`Lifetime Account Report ${account.name}`}
          backLink="/reports/accounts"
        />
        <div className="Report">
          <h2 className="title">Counts</h2>
          <div className="columns">
            { this.renderStat("Total Orders", reportData.counts.orders) }
            { this.renderStat("Cancelled Orders", reportData.counts.cancelled) }
            { this.renderStat("Customers", reportData.counts.customer) }
            { this.renderStat("Orders per Customer", (reportData.counts.orders/reportData.counts.customer).toFixed(2)) }
          </div>
          <h2 className="title">Paid Orders</h2>
          <div className="columns is-multiline">
            { this.renderStat("Order Count", reportData.counts.paid) }
            { this.renderStat("Total", reportData.totals.total) }
            { this.renderStat("Refunded", reportData.totals.refunded) }
            { this.renderStat("Cancelled Total", reportData.totals.cancelled) }
            { this.renderStat("Catch Fees", reportData.totals.fees) }
            { this.renderStat("Stripe Fees", `(${reportData.totals.stripe})`) }
            { this.renderStat("Profit", reportData.totals.profit) }
          </div>
          <h2 className="title">All Inclusive</h2>
          <div className="columns is-multiline">
            { this.renderStat("Order Count", reportData.counts.allInclusive) }
            { this.renderStat("Total", reportData.totals.allInclusive) }
            { this.renderStat("Refunded", reportData.totals.allInclusiveRefunded) }
            { this.renderStat("Catch Fees", reportData.totals.allInclusiveFees) }
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data", "history"], AccountReport)
