import "./AccountUsers.scss";

import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "lodash"
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"
import Table from "Table"
import Header from "Header"
import Select from "Select"
import DisplayField from "DisplayField"

// export default class AccountUsers extends React.PureComponent {
class AccountUsers extends React.PureComponent {
  state = {}

  handleInviteUser = () => {
    if (this.state.invitingUser) return false

    const { formData } = this.props
    let user = {
      name: formData.name,
      email: formData.email,
      venueId: formData.venueId,
      accountId: this.props.account.id,
      role: this.props.role || "venue",
    }

    this.setState({invitingUser: true})

    let req = request.post(`internal/users`, { user: user })
    req.then(({data}) => {
      if (data.success) {
        this.setState({invitingUser: false})
        this.props.clearForm()
        this.props.fetchData(`internal/accounts/${user.accountId}`)
      } else {
        this.setState({userErrors: data.errors, invitingUser: false})
      }
    })
  }

  venueOptions = () => {
    const opts = {}
    this.props.venues.map((v) => opts[v.id] = v.name)
    return opts
  }


  render() {
    const { account, formData, updateField } = this.props

    return(
      <div className="AccountUsers">
        <div className="s-card">
          <h3 className="title"> Users </h3>
          <Table
            headers={USER_HEADERS}
            showHeader={true}
            formatters={{
              name: (u) => <Link to={`/users/${u.id}`}>{u.name}</Link>,
              type: (u) => capitalize(u.type)
            }}
            rows={this.props.users}
          />
          <Form
            wrapper={false}
            title="Invite User"
            onSubmit={this.handleInviteUser}
          >
            <Input label="Name" value={formData.name} onChange={updateField.bind(null, "name")} />
            <Input
              label="Email"
              value={formData.email}
              onChange={updateField.bind(null, "email")}
              errors={this.state.userErrors}
              errorKey="email"
            />

            <Select
              label="Venue"
              value={formData.venueId}
              onChange={updateField.bind(null, "venueId")}
              options={this.venueOptions()}
              prompt="Select Venue for User"
            />

            <button
              className={`button is-link ${this.state.invitingUser ? "is-loading" : ""}`}
              disabled={!formData.name || !formData.email || !formData.venueId}
            >
              Invite User
            </button>
          </Form>
        </div>
      </div>
    )
  }
}

const USER_HEADERS = {
  name: "Name",
  email: "Email",
  type: "Role",
}

export default usesFeatures(["form"], AccountUsers)
