import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import wrapComponent from "ApplicationWrapper"
import User from "User"

import CrossLogin from "CrossLogin"
import FourOhFour from "FourOhFour"
import ProtectedRoutes from 'ProtectedRoutes'

import LoginView from "Internal/LoginView"

import DashboardView from "Internal/DashboardView"

import UserList from "Internal/UserList"
import UserView from "Internal/UserView"
import UserForm from "Internal/UserForm"

import AccountList from "Internal/AccountList"
import AccountView from "Internal/AccountView"
import AccountForm from "Internal/AccountForm"

import VenueList from "Internal/VenueList"
import VenueView from "Internal/VenueView"
import VenueForm from "Internal/VenueForm"

import OrderList from "Internal/OrderList"
import OrderView from "Internal/OrderView"

import PrinterList from "Internal/PrinterList"
import PrinterView from "Internal/PrinterView"

import CardReaderList from "Internal/CardReaderList"

import InvoiceList from "Internal/InvoiceList"
import InvoiceView from "Internal/InvoiceView"
import InvoiceForm from "Internal/InvoiceForm"

import EventList from "Internal/EventList"
import EventView from "Internal/EventView"
import EventForm from "Internal/EventForm"

import ReportList from "Internal/ReportList"
import AccountReport from "Internal/AccountReport"
import ReportView from "VenueAdmin/ReportView"

import PayoutList from "Internal/PayoutList"
import PayoutView from "Internal/PayoutView"
import PayoutForm from "Internal/PayoutForm"

import SeasonList from "Internal/SeasonList"
import SeasonView from "Internal/SeasonView"
import SeasonForm from "Internal/SeasonForm"

import VendorList from "Internal/VendorList"
import VendorView from "Internal/VendorView"
import VendorForm from "Internal/VendorForm"

import PermissionList from "Internal/PermissionList"
import EngineeringView from "Internal/EngineeringView"
import SalesToolsView from "Internal/SalesToolsView"

import DeliverectDashboard from "Internal/DeliverectDashboard"

const resources = (resource, includeNew, index, show, form) => {
  return(
    <Switch>
      <Route path={`/${resource}`} exact component={wrapComponent(index, "Internal")} />
      { includeNew && <Route path={`/${resource}/new`} exact component={wrapComponent(form, "Internal")} /> }
      <Route path={`/${resource}/:id`} exact component={wrapComponent(show, "Internal")} />
      <Route path={`/${resource}/:id/edit`} exact component={wrapComponent(form, "Internal")} />
    </Switch>
  )
}

export default props => (
  <Router>
    <Switch>
      <Route path="/login" exact component={wrapComponent(LoginView, "Auth")} />
      <Route path="/cross-login" exact component={CrossLogin} />

      <ProtectedRoutes auth={User}>
        <Switch>
          <Route path="/" exact component={wrapComponent(DashboardView, "Internal", "fullwidth")} />

          <Route path="/permissions" exact component={wrapComponent(PermissionList, "Internal")} />

          <Route path="/orders" exact component={wrapComponent(OrderList, "Internal")} />
          <Route path="/orders/:id" exact component={wrapComponent(OrderView, "Internal")} />

          <Route path="/reports" exact component={wrapComponent(ReportList, "Internal")} />
          <Route path="/reports/accounts" exact component={wrapComponent(AccountReport, "Internal")} />
          <Route path="/reports/accounts/:accountId" exact component={wrapComponent(AccountReport, "Internal")} />
          <Route path="/reports/:reportId" exact component={wrapComponent(ReportView, "None")} />

          <Route path="/printers" exact component={wrapComponent(PrinterList, "Internal")} />
          <Route path="/printers/:id" exact component={wrapComponent(PrinterView, "Internal")} />

          <Route path="/readers" exact component={wrapComponent(CardReaderList, "Internal")} />

          <Route path="/eng" exact component={wrapComponent(EngineeringView, "Internal")} />
          <Route path="/sales" exact component={wrapComponent(SalesToolsView, "Internal")} />

          <Route path="/deliverect" exact component={wrapComponent(DeliverectDashboard, "Internal")} />
        </Switch>

        { resources("accounts", true, AccountList, AccountView, AccountForm) }
        { resources("invoices", true, InvoiceList, InvoiceView, InvoiceForm) }
        { resources("events", true, EventList, EventView, EventForm) }
        { resources("payouts", true, PayoutList, PayoutView, PayoutForm) }
        { resources("seasons", true, SeasonList, SeasonView, SeasonForm) }
        { resources("users", true, UserList, UserView, UserForm) }
        { resources("vendors", true, VendorList, VendorView, VendorForm) }
        { resources("venues", true, VenueList, VenueView, VenueForm) }
      </ProtectedRoutes>

      <Route path="/*" component={wrapComponent(FourOhFour)} />
    </Switch>
  </Router>
)
