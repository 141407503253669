import "./OrderList.scss";

import React from "react";
import { Link } from "react-router-dom";
import usesFeatures from "usesFeatures"
import { formatDateTime } from "date_helpers"
import { startCase } from "lodash"

import Table from "Table"
import Icon from "Icon"
import Header from "Header"
import Paginate from "Paginate"

class OrderList extends React.Component {
  componentDidMount() {
    this.props.fetchData("internal/orders")
  }

  orderFormatters = () => {
    return({
      type: (o) => <Icon icon={o.type == "terminal" ? "cash-register" : "mobile"} />,
      total: (o) => `$${o.total}`,
      status: (o) => startCase(o.status),
      method: (o) => o.hasDelivery ? "Delivery" : "Pickup",
      refunded: (o) => o.refundCount > 0 ? "Yes" : "No",
      placedAt: (o) => formatDateTime(o.placedAt),
      view: (o) => <Link to={`/orders/${o.id}`}>View</Link>
    })
  }

  changePage = (page) => {
    this.props.getPage("internal/orders", page)
  }

  render() {
    if (!this.props.dataFetched) return false
    console.log(this.props)

    return(
      <div className="OrderList">
        <Header
          text="Orders"
          actions={true}
        />
        <Table
          showHeader={true}
          headers={ORDER_HEADERS}
          formatters={this.orderFormatters()}
          rows={this.props.pageData}
        />
        <Paginate
          changePage={this.changePage}
          {...this.props.pagy}
        />
      </div>
    )
  }
}

const ORDER_HEADERS = {
  type: "",
  customerName: "Customer",
  status: "Status",
  method: "Method",
  accountName: "Account",
  storeName: "Store",
  total: "Total",
  refunded: "Refunded?",
  placedAt: "Placed",
  view: "",
}

export default usesFeatures(["data", "pagination"], OrderList)
