import "./AccountView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { formatDate } from "date_helpers"
import { capitalize, startCase } from "lodash"
import { ApplicationContext } from "ApplicationContext"
import Flash from "flash"

import Form from "Form"
import Card from "Card"
import Input from "Input"
import Table from "Table"
import Header from "Header"
import Select from "Select"
import DisplayField from "DisplayField"
import Switch from "Switch"

import AccountUsers from "Internal/AccountUsers"

// export default class AccountView extends React.PureComponent {
class AccountView extends React.PureComponent {
  state = {
    updatingFeature: false
  }
  static contextType = ApplicationContext

  componentDidMount() {
    const accountId = this.props.match.params.id
    this.props.fetchData(`internal/accounts/${accountId}`)
  }

  stripeLink = () => {
    //test/connect/accounts/acct_1JmfjXPmRCpPYMLz/activity
    let urlParts = [
      "https://dashboard.stripe.com",
      "connect/accounts",
      this.props.account.stripeId
    ]
    if (process.env.NODE_ENV == "development") urlParts.splice(1, 0, "test")

    return urlParts.join("/")
  }

  createStripe = () => {
    if (this.state.stripeDisabled) return false
    this.setState({stripeDisabled: true})
    const { account } = this.props
    let req = request.post(`internal/accounts/${account.id}/stripe`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/accounts/${account.id}`)
      }
    })
  }

  toggleFeature = (featureName) => {
    this.setState({updatingFeature: true})
    let req = request.post(`internal/accounts/${this.props.account.id}/toggle_feature`, { feature: featureName })
    req.then(({data}) => {
      this.props.updateWith({account: data.account})
    this.setState({updatingFeature: false})
    })
  }

  handleAccountSwitch = () => {
    let req = request.post(`internal/accounts/${this.props.account.id}/switch`)
    req.then(({data}) => {
      if (data.success) {
        this.props.fetchData(`internal/accounts/${this.props.account.id}`)
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { account, formData, updateField } = this.props
    const { user } = this.context.CurrentUserContext
    return(
      <div className="AccountView p-2">
        <Header
          text="Accounts"
          backLink="/accounts"
          linkText="Back to Accounts"
          actions={true}
        >
          <Link to={`/accounts/${account.id}/edit`} className="button is-primary">Edit Account</Link>
          { account.id != user.accountId &&
            <span onClick={this.handleAccountSwitch} className="button is-warning">Switch Account</span>
          }
          { account.stripeId && <a href={this.stripeLink()} target="_blank" className="button is-link">View in Stripe</a> }
          { !account.stripeId &&
            <span className={`button is-link ${this.state.stripeDisabled ? "is-loading" : ""}`} onClick={this.createStripe}>Create Stripe Account</span>
          }
        </Header>

        <div className="columns">
          <div className="column is-6">
            <div className="s-card block">
              <h3 className="title is-flex is-relative">
                {account.name}
                { account.id == user.accountId &&
                  <span className="tag is-success is-align-self-flex-start ml-auto">Current Account</span>
                }

              </h3>
              <DisplayField
                label="Stripe Status"
                value={<>
                  <span>
                    Details Submitted? <span className={`tag ${account.detailsSubmitted ? "is-success" : "is-danger"}`}>
                      {account.detailsSubmitted ? "Yes":"No"}
                    </span>
                  </span>
                  <span className="ml-4">
                    Charges Enabled? <span className={`tag ${account.chargesEnabled ? "is-success" : "is-danger"}`}>
                      {account.chargesEnabled ? "Yes":"No"}
                    </span>
                  </span>
                </>}
              />
              <DisplayField label="Country" value={account.country.name} />
              <DisplayField label="Currency" value={account.country.currency} />
              <DisplayField label="Statement Descriptor" value={`CATCH* @${account.statementId}`} />
              <div className="even-row">
                <DisplayField label="Contract Start" value={account.contractStart ? formatDate(account.contractStart) : "None"} />
                <DisplayField label="Contract End" value={account.contractEnd ? formatDate(account.contractEnd) : "None"} />
              </div>
              <h4 className="subtitle">Fees</h4>
              <DisplayField label="Fee Structure" value={`${account.feeStructure}%`} />
              <DisplayField label="Tax + Tip Fees" value={`${account.otherFeeStructure}%`} />
              <DisplayField label="Per Transaction Fees" value={`$${account.transactionFee}`} />
              <DisplayField label="All-inclusive Fees" value={`${account.allInclusiveFeeStructure}%`} />
              <DisplayField label="Customer Fees" value={`${account.customerFeeStructure}%`} />
              <DisplayField label="Customer Fee Descriptor" value={account.customerFeeDescriptor} />
              <h4 className="subtitle">Terminal</h4>
              <div className="even-row">
                <DisplayField label="Terminal Fee Structure" value={`${account.terminalFeeStructure}%`} />
                <DisplayField label="Terminal Transaction Fee" value={`$${account.terminalTransactionFee}`} />
                <DisplayField label="Paid By" value={account.terminalCustomerFees ? "Customer" : "Account"} />
              </div>
            </div>
            <div className="s-card block">
              <h3 className="title">Venues</h3>

              <Table
                headers={VENUE_HEADERS}
                showHeader={true}
                formatters={{
                  name: (u) => <Link to={`/venues/${u.id}`}>{u.name}</Link>,
                  edit: (u) => <Link to={`/venues/${u.id}/edit`}>Edit</Link>,
                }}
                rows={this.props.venues}
              />
            </div>
          </div>
          <div className="column is-6">
            <Card title="Features">
              { account.availableFeatures.map((f, i) => {
                return(
                  <div key={i} className="is-flex is-align-items-center">
                    <Switch
                      className="mb-0"
                      disabled={this.state.updatingFeature}
                      value={account.features.includes(f)}
                      onChange={this.toggleFeature.bind(null, f)}
                    />
                    <p className="is-fw-bold mb-3 ml-2">
                      {startCase(f)}
                    </p>
                  </div>
                )
              })}

            </Card>
            <AccountUsers
              account={account}
              users={this.props.users}
              venues={this.props.venues}
              fetchData={this.props.fetchData}
            />
          </div>
        </div>
      </div>
    )
  }
}

const VENUE_HEADERS = {
  name: "Name",
  edit: "",
}

export default usesFeatures(["data", "form"], AccountView)
