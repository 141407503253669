import React, { useState, useEffect } from "react";
import request from "request"

import { fromPairs } from "lodash"

import Table from "Table"
import Button from "Button"
import Select from "Select"
import Icon from "Icon"
import Modal from "Modal"
import Flash from "flash"

const Terminals = ({venue, terminals}) => {
  const [addTerminalOpen, setAddTerminalOpen] = useState(false)
  const [cardReaders, setCardReaders] = useState([])
  const [stores, setStores] = useState([])
  const [reassigningId, setReassigningId] = useState(null)
  const [reassignedStore, setReassignedStore] = useState(null)
  const [reassigning, setReassigning] = useState(false)

  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedCardReader, setSelectedCardReader] = useState(null)

  const fetchCardReaders = () => {
    const req = request.get("card_readers", { params: { venueId: venue.id }})
    req.then(({data}) => setCardReaders(data.cardReaders))
  }

  const fetchStores = () => {
    const req = request.get("stores", { params: { venueIdEq: venue.id }})
    req.then(({data}) => setStores(data.stores))
  }

  useEffect(fetchCardReaders, [])
  useEffect(fetchStores, [])

  const clearState = () => {
    setSelectedStore(null)
    setSelectedCardReader(null)
    setAddTerminalOpen(false)
  }

  const createTerminal = () => {
    const req = request.post("terminals", { terminal: { storeId: selectedStore, internalCardReaderId: selectedCardReader }})
    req.then(({data}) => {
      clearState()
    })
    req.catch(() => {
      Flash.broadcast("error", "That card reader is already assigned. Please try again")
    })
  }

  const formatters = {
    number: (t) => `#${t.number}`,
    store: (t) => t.store.name,
    reassign: (t) => <Button text={<Icon icon="redo" />} color="blue" onClick={() => setReassigningId(t.id)} />,
    delete: (t) => <Button text={<Icon icon="trash" />} color="red" />,
    printer: (t) => <Icon icon={t.hasPrinter ? "check" : "times"} />
  }

  const reassignTerminal = () => {
    setReassigning(true)
    const req = request.patch(`terminals/${reassigningId}/reassign`, { storeId: reassignedStore })
    req.then(({data}) => {
      Flash.broadcast("success", "Terminal Reassigned. Reload to see updates")
      closeReassignModal()
    })
    req.catch(() => {
      Flash.broadcast("error", "There was an issue reassigning this temrinal")
      setReassigning(false)
    })
  }

  const closeReassignModal = () => {
    setReassignedStore(null)
    setReassigningId(null)
    setReassigning(false)
  }

  let reassignmentTerminal = terminals.find((t) => t.id === reassigningId)

  return(
    <div>
      <Table
        headers={TERMINAL_HEADERS}
        formatters={formatters}
        rows={terminals}
        actions={
          <Button text="Add Terminal" onClick={() => setAddTerminalOpen(true)} />
        }
      />
      { addTerminalOpen && <div>
        <Select
          prompt={"Select a Store"}
          options={fromPairs(stores.map((s) => [ s.id, s.name ]))}
          onChange={setSelectedStore}
          value={selectedStore}
        />

        <Select
          prompt={"Select a Card Reader"}
          options={fromPairs(cardReaders.map((cr) => [ cr.id, cr.serialNumber ]))}
          onChange={setSelectedCardReader}
          value={selectedCardReader}
        />

        <Button
          text="Create Terminal"
          disabled={!selectedStore || !selectedCardReader}
          onClick={createTerminal}
        />
      </div>}
      <Modal
        title="Reassigning Terminal"
        isOpen={!!reassigningId}
        toggleModal={closeReassignModal}
        actions={
          <>
            <span className="button is-outline ml-auto" onClick={closeReassignModal}>Cancel</span>
            <Button disabled={!reassignedStore} loading={reassigning} onClick={reassignTerminal} text="Reassign" />
          </>
        }
      >
        <div>
          <p className="is-size-5">
            Reassigning&nbsp;
            {
              [
                reassignmentTerminal?.store?.name,
                reassignmentTerminal?.number,
              ].join(" #")
            }
          </p>
          <Select
            prompt={"Select a Store"}
            options={fromPairs(stores.map((s) => [ s.id, s.name ]))}
            onChange={setReassignedStore}
            value={reassignedStore}
            className="mt-2"
          />
        </div>
      </Modal>
    </div>
  )
}

const TERMINAL_HEADERS = {
  number: "",
  store: "Store",
  authCode: "Auth Code",
  printer: "Printer",
  reassign: "",
  delete: "",
}

export default Terminals
